import moment from "moment/moment.js";
import 'moment/locale/pt-br';
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import ComAberturaJuntaBNF from "../../components/Emails/ComAberturaJuntaBNF.js";
import ComAberturaJuntaPA from "../../components/Emails/ComAberturaJuntaPA.js";
import ComDecisaoJuntaBNF from "../../components/Emails/ComDecisaoJuntaBNF.js";
import ComDecisaoJuntaPA from "../../components/Emails/ComDecisaoJuntaPA.js";
import ComEscolhaAbstencaoBNF from "../../components/Emails/ComEscolhaAbstencaoBNF.js";
import ComEscolhaAbstencaoPA from "../../components/Emails/ComEscolhaAbstencaoPA.js";
import ComEscolhaBNF from "../../components/Emails/ComEscolhaBNF.js";
import ComEscolhaPA from "../../components/Emails/ComEscolhaPA.js";
import ParOpiniaoEspecializada from "../../components/Emails/ParOpiniaoEspecializada.js";
import ParTecnicoConclusivo from "../../components/Emails/ParTecnicoConclusivo.js";
import SolExamesComplementaresBNF from "../../components/Emails/SolExamesComplementaresBNF.js";
import SolExamesComplementaresOPS from "../../components/Emails/SolExamesComplementaresOPS.js";
import ValConsensoParcial from "../../components/Emails/ValConsensoParcial.js";
import config from "../../config/config.js";
import business from "../../services/business.js";
import tools from "../../lib/tools.js";
import Loading from "../../components/Loading.js";
import { useParams } from "react-router-dom";
import ComRecusaConsensoParcialPA from "../../components/Emails/ComRecusaConsensoParcialPA.js";
import '../../assets/styles/pages/Dossie.scss'
import ComConsensoTecnicoOPS from "../../components/Emails/ComConsensoTecnicoOPS.js";
import ComConsensoTecnicoPA from "../../components/Emails/ComConsensoTecnicoPA.js";
import ComConsensoTecnicoBNF from "../../components/Emails/ComConsensoTecnicoBNF.js";

export default function DocDossie() {

    let { id_processo, id_processo_etapa_status } = useParams();
    document.title = 'säästo';
    const [notificacao, setNotificacao] = useState([]);
    const [historico, setHistorico] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        async function getProcess() {

            setNotificacao(await business.getDossie(id_processo))

            setHistorico(await business.getDossieHistorico(id_processo))

            setLoading(false);
        }

        getProcess();

    }, [id_processo]);


    const registro_historico = (historico || []).map((h) => {
        return {
            texto: h.desc_acao,
            data: moment(h.data_criacao).format('DD/MM/YYYY HH:mm:ss'),
            desc_processo_etapa: h.desc_processo_etapa,
            desc_processo_etapa_status: h.desc_processo_etapa_status
        }
    })

    const registro_comunicacoes = (notificacao || []).map((c) => {
        return {
            texto: c?.desc_conteudo,
            data_disparo: moment(c?.data_cadastro).format('DD/MM/YYYY HH:mm:ss'),
            data_format: moment(c?.data_cadastro).format('DD/MM/YYYY'),
            data_leitura: c?.data_leitura && moment(c?.data_leitura).format('DD/MM/YYYY HH:mm:ss'),
            codigo: c?.id + c?.processo?.disparo?.id_protocolo,
            id_notificacao_canal: c?.id_notificacao_canal,
            id_notificacao_conteudo: c?.id_notificacao_conteudo,
            desc_processo_etapa: c?.processo?.disparo?.desc_processo_etapa,
            canal: c?.desc_canal,
            receptorBNF: c?.id_beneficiario,
            receptorPA: c?.id_profissional,
            receptorOPS: c?.id_operadora,
            canalBNF: (c?.id_notificacao_canal === '2' ? c?.processo?.disparo?.beneficiario?.email : c?.processo?.disparo?.beneficiario?.telefone.replace(/(\d{2})(\d{4})(\d{4,5})$/, "($1) $2-$3")),
            canalPA: (c?.id_notificacao_canal === '2' ? c?.processo?.disparo?.profissional?.email : c?.processo?.disparo?.profissional?.telefone.replace(/(\d{2})(\d{4})(\d{4,5})$/, "($1) $2-$3")),
            canalOPS: (c?.id_notificacao_canal === '2' ? c?.processo?.disparo?.endereco_operadora?.email : c?.processo?.disparo?.endereco_operadora?.telefone.replace(/(\d{2})(\d{4})(\d{4,5})$/, "($1) $2-$3")),
            disparo: (c?.id_notificacao_canal === '2' ? 'sistema@saasto.com.br' : '(48) 9199-9368 (Whatsapp säästö)'),
            ip: c?.ip,
            navegador: c?.navegador,
            sistema_operacional: c?.sistema_operacional,
            processo: {
                protocolo: c?.processo?.disparo?.id_protocolo,
                operadora_nome: c?.processo?.disparo?.desc_operadora,
                operadora_endereco: c?.processo?.disparo?.endereco_operadora?.logradouro + ', ' + c?.processo?.disparo?.endereco_operadora?.numero + ', ' + c?.processo?.disparo?.endereco_operadora?.bairro + ', ' + c?.processo?.disparo?.endereco_operadora?.cidade + ' - ' + c?.processo?.disparo?.endereco_operadora?.uf,
                operadora_telefone: c?.processo?.disparo?.endereco_operadora?.telefone.replace(/(\d{2})(\d{4})(\d{4,5})$/, "($1) $2-$3") || 'Telefone indisponível',
                operadora_imagem: c?.processo?.disparo?.endereco_operadora?.imagem,
                data_disparo: moment(config.dataHoraAtual()).format('LL'),
                profissional_nome: c?.processo?.disparo?.profissional?.nome,
                profissional_crmcro: c?.processo?.disparo?.profissional?.crm,
                profissional_uf: c?.processo?.disparo?.profissional?.uf,
                profissional_telefone: c?.processo?.disparo?.profissional?.telefone.replace(/(\d{2})(\d{4})(\d{4,5})$/, "($1) $2-$3"),
                data_atendimento: moment(c?.processo?.disparo?.data_atendimento).format('DD/MM/YYYY'),
                beneficiario_nome: c?.processo?.disparo?.beneficiario?.nome,
                beneficiario_cpf: c?.processo?.disparo?.beneficiario?.cpf,
                beneficiario_sexo: c?.processo?.disparo?.beneficiario?.sexo === 'M' ? 'masculino' : 'feminino',
                beneficiario_guia: c?.processo?.disparo?.guia,
                beneficiario_nascimento: c?.processo?.disparo?.beneficiario?.nascimento,
                beneficiario_idade: tools.calcularIdade(c?.processo?.disparo?.beneficiario?.nascimento),
                auditor_nome: c?.processo?.disparo?.auditor?.nome,
                auditor_crmcro: c?.processo?.disparo?.auditor?.crm,
                auditor_uf: c?.processo?.disparo?.auditor?.uf,
                assistente_responsavel_nome: c?.processo?.disparo?.responsaveis?.nome_assistente,
                enfermeira_responsavel_nome: c?.processo?.disparo?.responsaveis?.nome_enfermeira,
                desempatador_responsavel_nome: c?.processo?.disparo?.responsaveis?.nome_desempatador,
                desempatador_responsavel_crmcro: c?.processo?.disparo?.responsaveis?.crm_desempatador || c?.processo?.disparo?.responsaveis?.cro_desempatador,
                desempatador_responsavel_uf: c?.processo?.disparo?.responsaveis?.crm_uf_desempatador || c?.processo?.disparo?.responsaveis?.cro_uf_desempatador,
                desempatador_responsavel_laudo: c?.processo?.disparo?.texto_parecer,
                data_parecer: moment(c?.processo?.disparo?.data_validacao).format('LL'),
                data_parecer_min: moment(c?.processo?.disparo?.data_validacao).format('DD/MM/YYYY'),
                procedimentos: (c?.processo?.disparo?.procedimentos || []).map((p, index) => {
                    return {
                        id: p.id_procedimento,
                        codigo: p?.codigo || '-',
                        descricao: p.desc_procedimento,
                        quantidade_solicitada: p.quantidade_solicitada || 0,
                        quantidade_autorizada: p.quantidade_aprovada || 0,
                        quantidade_pos_analise: p.quantidade_pos_analise || 0,
                        id_divergencia: p.id_processo_motivo_divergencia,
                        divergencia: p?.desc_processo_motivo_divergencia || '-',
                        justificativa: p.observacao || '-',
                        justificativa_pos_analise: p.justificativa_analise || '-'
                    };
                }),
                materiais: (c?.processo?.disparo?.materiais || []).map((m, index) => {
                    return {
                        id: m.id_material,
                        descricao: m.desc_material,
                        quantidade_solicitada: m.quantidade_solicitada || 0,
                        quantidade_autorizada: m.quantidade_aprovada || 0,
                        quantidade_pos_analise: m.quantidade_pos_analise || 0,
                        id_divergencia: m.id_processo_motivo_divergencia,
                        divergencia: m?.desc_processo_motivo_divergencia || '-',
                        justificativa: m.observacao || '-',
                        justificativa_pos_analise: m.justificativa_analise || '-'
                    };
                }),
                desempatadores: c?.processo?.disparo?.desempatadores,
                id_desempatador: c?.processo?.disparo?.responsaveis?.id_desempatador || 0,
                notificacao_envio: c?.processo?.disparo?.notificacao_envio || {},
                exames_complementares: [],
                conclusao: c?.processo?.processo_conclusao
            }
        }
    });

    return (
        <div>
            {loading ? (<div className="p-30 flex"><Loading /> <span className="pl-10">Aguarde, carregando informações e montando dossiê...</span></div>) :
                (<div className="p-40">
                    <div className="float-right" id="no-print">
                        <button className="btn btn-primary f700-16 jc-sb" onClick={() => (window.print())}>
                            Imprimir <i className="icon icon-print rotate-180 i-20 ml-10" />
                        </button>
                    </div>
                    <br />
                    <div className="w-100 text-center pt-30 pb-10">
                        <h2 className="text-center fb-18">DOSSIÊ - ID: 231088{id_processo}</h2>
                    </div>
                    <div className="w-100">
                        <div className="text-right mb-10">{moment(config.dataHoraAtual()).format('LL')}</div>
                    </div>
                    <p className="text-justify">Este dossiê apresenta um registro detalhado de todo o histórico do processo, do envio de comunicações às partes envolvidas, como também a visualização integra destas comunicações referentes ao processo de Junta Médica e/ou Odontológica de código 231088{id_processo}.</p>
                    <br />
                    <p className="dossie-header-session">1. Resumo do processo (workflow):</p>
                    {(registro_historico || []).map((hd, i) => {
                        return <div key={i}>
                            <div className="w-100 fn-14 pv-10 pl-10 flex list-line">
                                <div className="list-circle"></div>
                                <div className="ml-25">
                                    <span className="mb-5 bold list-header">{hd.texto}</span><br />
                                    <span className="mb-5"> Etapa/status: {hd.desc_processo_etapa} | {hd.desc_processo_etapa_status}</span><br />
                                    <b>Registro: {hd.data} hrs</b>
                                </div>
                            </div>
                        </div>
                    })}
                    <br />
                    <p className="dossie-header-session">2. Registros de envio e leitura das comunicações:</p>
                    {(registro_comunicacoes || []).map((nd, i) => {
                        return <div key={i}>
                            <div className="w-100 fn-14 pv-10 pl-10 flex list-line" >
                                <div className="list-circle"></div>
                                <div className="ml-25">
                                    <span className="mb-5 bold">Conteúdo: {nd.texto}</span><br />
                                    <span className="mb-5">Canal: {nd.canal}</span><br />
                                    <span className="mb-5">Etapa: {nd.desc_processo_etapa}</span><br />
                                    <span className="mb-5 bold">Envio: {nd.data_disparo} hrs</span><br />
                                    <span className={`mb-5 bold ${nd.data_leitura && 'font-green'}`}>Leitura: {nd.data_leitura ? <span className={`${nd.data_leitura && 'font-green'}`}>{nd.data_leitura} hrs</span> : '-'} </span>
                                </div>
                            </div>
                        </div>
                    })}
                    <br />
                    <p className="dossie-header-session">3. Registro geral de comunicações enviadas e seus conteúdos:</p>
                    {(registro_comunicacoes || []).map((item, i) => {
                        return <div key={i} className="email-doc">
                            {(item.id_notificacao_conteudo === '1') && <ComAberturaJuntaBNF resp={item.processo} notify={item} viewHeader={true} print={true} />}
                            {(item.id_notificacao_conteudo === '2') && <ComAberturaJuntaPA resp={item.processo} notify={item} viewHeader={true} print={true} disabled={true} />}
                            {(item.id_notificacao_conteudo === '3') && <ComConsensoTecnicoOPS resp={item.processo} notify={item} viewHeader={true} print={true} />}
                            {(item.id_notificacao_conteudo === '4') && <ComDecisaoJuntaBNF resp={item.processo} notify={item} viewHeader={true} print={true} />}
                            {(item.id_notificacao_conteudo === '5') && <ComDecisaoJuntaPA resp={item.processo} notify={item} viewHeader={true} print={true} />}
                            {(item.id_notificacao_conteudo === '6') && <ComEscolhaAbstencaoBNF resp={item.processo} notify={item} viewHeader={true} print={true} />}
                            {(item.id_notificacao_conteudo === '7') && <ComEscolhaAbstencaoPA resp={item.processo} notify={item} viewHeader={true} print={true} />}
                            {(item.id_notificacao_conteudo === '8') && <ComEscolhaBNF resp={item.processo} notify={item} viewHeader={true} print={true} />}
                            {(item.id_notificacao_conteudo === '9') && <ComEscolhaPA resp={item.processo} notify={item} viewHeader={true} print={true} />}
                            {(item.id_notificacao_conteudo === '10') && <ParOpiniaoEspecializada resp={item.processo} notify={item} viewHeader={true} print={true} />}
                            {(item.id_notificacao_conteudo === '13') && <SolExamesComplementaresBNF resp={item.processo} notify={item} viewHeader={true} print={true} />}
                            {(item.id_notificacao_conteudo === '14') && <SolExamesComplementaresOPS resp={item.processo} notify={item} viewHeader={true} print={true} />}
                            {(item.id_notificacao_conteudo === '15') && <ValConsensoParcial resp={item.processo} notify={item} viewHeader={true} print={true} />}
                            {(item.id_notificacao_conteudo === '17') && <ComRecusaConsensoParcialPA resp={item.processo} notify={item} viewHeader={true} print={true} />}
                            {(item.id_notificacao_conteudo === '19') && <ComConsensoTecnicoBNF resp={item.processo} notify={item} viewHeader={true} print={true} />}
                            {(item.id_notificacao_conteudo === '20') && <ComConsensoTecnicoPA resp={item.processo} notify={item} viewHeader={true} print={true} />}
                        </div>
                    })}
                    <br />
                    {![12, 42].includes(parseInt(id_processo_etapa_status)) && <div>
                        <p className="dossie-header-session">4. Registro de parecer técnico conclusivo:</p>
                        {(registro_comunicacoes || []).map((item, i) => {
                            return <div key={i} >
                                {(registro_comunicacoes.length - 1 === i) &&
                                    <div className="email-doc">
                                        <ParTecnicoConclusivo resp={item.processo} print={true} />
                                    </div>
                                }
                            </div>
                        })}
                    </div>}
                </div>)
            }
        </div >
    );
}