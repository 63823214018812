//import Cep from "cep-promise";
import React from 'react';
import Email from '../Email';

export default function ComRecusaConsensoParcialPA({ resp = {}, notify = false, print = false, viewHeader = false }) {

    return (
        <Email emailClass={`${print ? 'print-email' : 'email'}`}>
            {viewHeader && <div className="w-100 mb-20">
                <p className="mb-0"><b>De:</b> {notify?.disparo}</p>
                <p className="mb-0"><b>Para:</b> {notify?.canalPA}</p>
                <p className="mb-0"><b>Assunto:</b> COMUNICADO DE RECUSA DE CONSENSO PARCIAL PELA OPERADORA DE SAÚDE</p>
                <p className="mb-0"><b>Data:</b> {notify?.data_disparo}</p>
                <hr />
            </div>}
            <div className="flex">
                <div className="w-100 mb-20">
                    <p className="mb-0"><b>Operadora:</b> {resp.operadora_nome}</p>
                    <p className="mb-0"><b>Endereço:</b> {resp.operadora_endereco}</p>
                    <p className="mb-0"><b>Telefone:</b> {resp.operadora_telefone}</p>
                </div>
                <img className="image-ops" src={resp?.operadora_imagem} alt='Operadora'></img>
            </div>
            <div className="w-100">
                <p className="text-center mt-30 mb-30 fb-18">COMUNICADO DE RECUSA DE CONSENSO PARCIAL PELA OPERADORA DE SAÚDE</p>
            </div>
            <div className="w-100">
                <div className="text-right mb-10">{notify.data_format ? notify.data_format : resp.data_disparo}</div>
            </div>
            <p className="mb-10">
                Olá Dr(a).<b> {resp.profissional_nome}</b>.
            </p>
            <p>Informamos que foi realizado o encaminhamento das suas considerações na tentativa de consenso parcial para a análise da auditoria da {resp.operadora_nome}, representada pelo (a) Dr.(a) <b>{resp.auditor_nome}</b>, CRM (ou CRO): <b>{resp.auditor_crmcro}</b> - <b>{resp.auditor_uf}</b>, e que elas não foram acatadas.</p>
            <p>Sendo assim, prosseguiremos com o processo de Junta Médica ou Odontológica. Conforme mencionado na Comunicação de Abertura, solicitamos que você faça a escolha do profissional desempatador dentre as opções abaixo:</p>
            <br />

            {resp?.desempatadores?.map((desempatador, i) => {
                i++;
                return <div key={desempatador.id}>
                    <p className="mb-20"><b>{i}ª opção: {desempatador.nome} – CRM/CRO: {desempatador.crm || desempatador.cro} – {desempatador.crm_uf || desempatador.cro_uf}</b></p>
                    {(desempatador.descricao) && <p className="mb-20">{desempatador.descricao}</p>}
                </div>
            })}

            <p>Ou se preferir, você pode <b>se abster dessa escolha.</b></p>
            <br />
            <p>Conforme informado na comunicação de abertura, o seu prazo para escolha do desempatador é de dois dias úteis. Caso esse prazo já tenha expirado, daremos continuidade no processo.</p>
            <br />
            <p>Em caso de dúvidas, retorne este e-mail ou fale conosco pelo WhatsApp (48) 99201-4736.</p>
            <br />

            <div className="w-100 text-justify bold pb-50">
                <p>Atenciosamente,</p>
                <p>Equipe säästö.</p>
            </div>
            {notify.data_disparo && <div className="w-100 alert-label">
                <p className="text-center pt-10 bold">Registro de envio de comunicação ao profissional assistente via {notify?.canal}: {notify?.canalPA} - {notify?.data_disparo} - Cód.{notify?.codigo}</p>
            </div>}
            <br/>
            {notify.data_leitura && <div className="w-100 alert-label">
                <p className="text-center pt-10 bold">Registro de leitura de comunicação por profissional assistente via {notify?.canal}: {notify?.canalPA} - {notify?.data_leitura} - IP: {notify?.ip} / {notify?.navegador} / {notify?.sistema_operacional} - Cód.{notify?.codigo}</p>
            </div>}
        </Email>
    );
}
