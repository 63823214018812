import moment from "moment/moment.js";
import React, { useCallback, useState } from "react";
import { useEffect, useRef } from "react";
import useDropdownMenu from "react-accessible-dropdown-menu-hook";
import config from "../../config/config.js";
import tools from "../../lib/tools.js";
import business from "../../services/business.js";
import Loading from "../Loading.js";
import Pusher from 'pusher-js';
import { useAlert } from "react-alert";

export default function Notificacao({ className = '' }) {

  const user = useRef(config.user?.id);

  const alert = useAlert();
  const { buttonProps, isOpen, setIsOpen } = useDropdownMenu(1);
  const [notificacoes, setNotificacoes] = useState([]);
  const [loadStatus, setLoadStatus] = useState(false);
  const [renderId, setRenderId] = useState(false);

  const fetchData = useCallback(async (update = false) => {
    setLoadStatus(true);

    let data = localStorage.getItem('notifications');

    if (data && !update) {
      setNotificacoes(JSON.parse(data) || []);
      return setLoadStatus(false);
    }

    console.log(`Notificações: lendo notificações`)

    let response = await business.getNotificacao({
      tipo: 3,
      usuario: user.current
    }, 5);
    if (response) {
      setNotificacoes(response)
      localStorage.setItem('notifications', JSON.stringify(response));
      console.log(`Notificações: completo`)
    }

    setLoadStatus(false);
  }, []);

  useEffect(() => {

    const pusher = new Pusher('cc3ec1a7305f21c3275e', {
      cluster: 'sa1',
      encrypted: true
    });

    console.log(`Push: assinando o canal "saa-tipo-3"`)

    const channel_history = pusher.subscribe(`saa-tipo-3`);

    channel_history.bind('update', function (data) {
      const { mensagem, id_usuario } = JSON.parse(data.message);
      if (config.user?.id && parseInt(config.user.id) !== parseInt(id_usuario)) {
        console.log(`Push: notificando no canal "saa-tipo-3"`)
        alert.success(mensagem);
        fetchData(true);
      }
    });

    const channel_user = pusher.subscribe(`saa-usuario-${config.user?.id}`);

    console.log(`Push: assinando o canal "saa-usuario-${config.user?.id}"`)

    channel_user.bind('update', function (data) {
      const { mensagem, id_usuario } = JSON.parse(data.message);
      if (config.user?.id && parseInt(config.user.id) !== parseInt(id_usuario)) {
        console.log(`Push: notificando no evento "saa-usuario-${config.user?.id}:update"`)
        alert.success(mensagem);
        fetchData(true);
      }
    });
    // eslint-disable-next-line
  }, [alert]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const totalNaoLido = (notificacoes || []).filter((item) => {
    return parseInt(item.lido) === 0;
  })?.length || 0;

  const marcarComoLido = async (id, i, callback = false) => {
    const response = await business.setNotificacaoLida(id);
    if (response?.status) {
      notificacoes[i].lido = true;
      setNotificacoes(notificacoes);
      localStorage.setItem('notifications', JSON.stringify(notificacoes));
      setRenderId(tools.uuidv4());
      if (callback) {
        callback();
      }
    }
  }

  useState(() => { }, [renderId]);

  return (
    <div className={`relative ${className}`} id="widget-notifications">
      <div className={`icone-notificacao ${isOpen && 'active'}`}>
        <i className="icon icon-bell blue i-20 pointer" onClick={(e) => e.stopPropagation()}  {...buttonProps} />
      </div>
      {totalNaoLido > 0 && <div className="alerta f700-10 flex ai-fc jc-fc">{totalNaoLido}</div>}
      <div className={`dropdown-submenu header-notificacoes ${isOpen ? 'visible' : ''} flex fd-fc`} role='menu'>
        <h1 className="flex jc-sb ai-fc f200-20 mt-30 ml-30 mb-20 mr-35">
          Notificações
          <span className="close pointer" onClick={() => setIsOpen(false)}>
            <i className="icon icon-cross-small i-25 watermelon" />
          </span>
        </h1>
        <div className="flex fd-fc container-notificacao scrollfit">
          {!loadStatus && (notificacoes || []).map((n, i) => {
            return <div key={`notificacao-${i}`}>
              <div className={`flex ai-fc notificacao ${(parseInt(n.lido) === 0) ? 'nao-lido' : ''} mb-15 pointer gap-20`} onClick={() => marcarComoLido(n.id, i)} >
                <div className="flex fd-fc" onClick={() => {

                  let url_redirect = `/${n.slug}/${n.id_processo}`;

                  // auto open tab chat
                  if (n?.id_acao && parseInt(n.id_acao) === 790) {
                    url_redirect += '#comentarios';
                  }

                  if (parseInt(n.lido) === 0) {
                    marcarComoLido(n.id, i, () => {
                      tools.openURL(url_redirect);
                    });
                  } else {
                    tools.openURL(url_redirect);
                  }
                }}>
                  <p className="f400-14 mb-15"><span className="link">ID: {n.id_protocolo}</span> - {n.slug === 'opiniao-especializada' && 'Opinião Especializada'}{n.slug === 'consenso-pre' && 'Consenso Pré'}{n.slug === 'junta-medica' && 'Junta Médica Odontológica'}</p>
                  <p className="f400-14 m-0"><b>{n.desc_acao}</b></p>
                  <p className="f400-12 junta_medica mv-5">{n.id_acao !== '100' ? n.desc_processo_etapa + ': ' + n.desc_processo_etapa_status : '' }</p>
                  <p className="f400-12 junta_medica mv-5">{moment(n.data_criacao, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY - HH:mm')} hrs</p>
                </div>
                {n.lido && <div className="alert-nao-lido" />}
              </div>
              <div className="line-bottom" />
            </div>
          })}
          {loadStatus && <div className="flex ai-fc notificacao"><Loading /></div>}
        </div>
      </div>
    </div>
  );
}

