import React, { useState } from "react";
import config from "../../../config/config.js";
import business from "../../../services/business.js";
import InputForm from "../../Form/InputForm.js";
import Loading from "../../Loading.js";
import ToggleSwitch from "../../ToggleSwitch.js";
import ModalProcedimentos from "../../../components/Modais/ModalProcedimentos.js";
import ModalAlert from "../../Modais/ModalAlert.js";
import tools from "../../../lib/tools.js";
import { useRef } from "react";
import ModalMateriais from "../../Modais/ModalMateriais.js";

export default function DadosProcesso({
  className = "mt-60",
  salvar,
  form,
  setModel,
  validacao = {},
  options,
  modoEdicao = true,
  updateStatus = false,
  permitirEdicao = false,
}) {
  const searchProcedimento = async (e) => {
    return await business.getProcedimento(e.target.value);
  };
  const searchMateriais = async (e) => {
    return await business.getMateriais(e.target.value);
  };

  const min = 0;
  const max = 1000;

  const [procedimentos, setProcedimentos] = useState(form?.procedimentos || []);
  const [materiais, setMateriais] = useState(form?.materiais || []);
  const [exibicao, setExibicao] = useState(modoEdicao);
  const [showModalProcedimentos, setShowModalProcedimentos] = useState(false);
  const [showModalMateriais, setShowModalMateriais] = useState(false);
  const [modalData, setModalData] = useState({});

  const childToParent = () => {
    setShowModalProcedimentos(true);
  };

  const childToParentSecundary = () => {
    setShowModalMateriais(true);
  };

  const hideModalProcedimentos = async (event) => {
    event?.preventDefault();
    setShowModalProcedimentos(false);
    procedimentosRef.current.clearSearch();
    const body = document.body;
    body.classList.remove("overflow-hidden");
  };

  const hideModalMateriais = async (event) => {
    event?.preventDefault();
    setShowModalMateriais(false);
    materiaisRef.current.clearSearch();
    const body = document.body;
    body.classList.remove("overflow-hidden");
  };

  const cancel = () => {
    setExibicao(!exibicao);
  };

  const setProcedimento = (search) => {
    if (!search || typeof search !== "object") {
      return;
    }

    if (
      procedimentos.find(
        (p) => parseInt(p?.procedimento?.id) === parseInt(search?.id)
      )
    ) {
      return setModalData({
        text: `O procedimento ${search?.codigo} já consta no processo.`,
        show: true,
        buttonCancel: false,
        onAccept: async (e) => {
          setModalData({
            show: false,
          });
        },
      });
    }

    let dto = {
      id: search.id,
      procedimento: search,
      data: {
        codigo: search?.codigo,
        desc: search?.nome,
        id_procedimento: search?.id,
        quantidade_solicitada: 1,
        quantidade_aprovada: 0,
        quantidade_pos_analise: "",
        id_processo_motivo_divergencia: "",
        valor_unitario: tools.prepareValue(search?.valor_unitario) ?? 0,
        rol: parseInt(search?.cobertura_rol),
        dut: search?.dut,
        observacao: "",
        justificativa_analise: "",
      },
    };

    procedimentos.unshift(dto);
    form.procedimentos = procedimentos;
    setProcedimentos(procedimentos);
    setModel("procedimentos", procedimentos);
    calculaTotal();
  };

  const removerProcedimento = (e, i) => {
    e.preventDefault();
    procedimentos.splice(i, 1);
    form.procedimentos = procedimentos;
    setProcedimentos([...procedimentos]);
    setModel("procedimentos", procedimentos);
    calculaTotal();
  };

  const setMaterial = (search) => {
    if (!search || typeof search !== "object") {
      return;
    }

    if (
      materiais.find((p) => parseInt(p?.material?.id) === parseInt(search?.id))
    ) {
      return setModalData({
        text: `O material ${search?.codigo} já consta no processo.`,
        show: true,
        buttonCancel: false,
        onAccept: async (e) => {
          setModalData({
            show: false,
          });
        },
      });
    }
    let dto = {
      id: materiais?.length ? materiais.length + 1 : 1,
      material: search,
      data: {
        codigo: search?.codigo,
        desc: search?.nome,
        id_material: search.id,
        quantidade_solicitada: 1,
        quantidade_aprovada: 0,
        quantidade_pos_analise: "",
        id_processo_motivo_divergencia: "",
        valor_unitario: tools.prepareValue(search?.valor_unitario) ?? 0,
        observacao: "",
        justificativa_analise: "",
      },
    };

    materiais.unshift(dto);
    form.materiais = materiais;
    setMateriais(materiais);
    setModel("materiais", materiais);
    calculaTotal();
  };

  const removerMaterial = (e, i) => {
    e.preventDefault();
    materiais.splice(i, 1);
    form.materiais = materiais;
    setMateriais([...materiais]);
    setModel("materiais", materiais);
    calculaTotal();
  };

  const calculaTotal = () => {
    let valor_total = 0;

    (materiais || []).map((item) => {
      if (item.data.valor_unitario)
        valor_total +=
          tools.prepareValue(item.data?.quantidade_solicitada || 0) *
          tools.prepareValue(item.data.valor_unitario);
      return item;
    });

    (procedimentos || []).map((item) => {
      if (item.data.valor_unitario)
        valor_total +=
          tools.prepareValue(item.data?.quantidade_solicitada || 0) *
          tools.prepareValue(item.data.valor_unitario);
      return item;
    });

    form.valor_total = tools.prepareValue(valor_total);
    setModel("valor_total", form.valor_total);
  };

  const procedimentosRef = useRef();
  const materiaisRef = useRef();

  return (
    <div className={className}>
      <ModalAlert
        title={modalData?.title}
        text={modalData?.text}
        show={modalData?.show}
        onAccept={modalData?.onAccept}
        onClose={modalData?.onClose}
        buttonCancel={modalData?.buttonCancel}
        setModel={setModalData}
      />
      {showModalProcedimentos && (
        <ModalProcedimentos
          show={showModalProcedimentos}
          hideModal={hideModalProcedimentos}
          processo={form}
          model={setProcedimento}
        />
      )}
      {showModalMateriais && (
        <ModalMateriais
          show={showModalMateriais}
          hideModal={hideModalMateriais}
          processo={form}
          model={setMaterial}
        />
      )}
      <h2 className="f600-18 mb-30 flex jc-sb ai-fc">
        Divergências/Análises (
        {procedimentos?.length ? (procedimentos?.length + (procedimentos?.length > 1 ? " procedimentos" : " procedimento")) : ''}
        {(procedimentos?.length && materiais?.length) ? " e " : ''}
        {materiais?.length ? (materiais?.length + (materiais?.length > 1 ? " materiais" : " material")) : ''}
        )
      </h2>
      {form.id_processo_etapa === "12" ||
        config?.user.tipo === "1" ||
        config?.user.tipo === "4" ||
        config?.user.tipo === "5" ||
        config?.user.tipo === "8" ? (
        <div className="form-container grid col-12 w-100 mb-45">
          <InputForm
            label="Procedimento"
            ref={procedimentosRef}
            type="search"
            name="busca_procedimentos"
            className="span-5 personalize-search"
            onSearch={searchProcedimento}
            model={setProcedimento}
            limpar={true}
            searchCodigo={"codigo"}
            viewMore={true}
            childToParent={childToParent}
          />
        </div>
      ) : (
        <div className="group-input f400-18">
          <label className="f700-20 black-text mb-20">
            <span className="marker" />
            Procedimentos:
          </label>
        </div>
      )}
      {!procedimentos.length && (
        <div className="empty-procedures w-100" style={{ height: "310px" }}>
          <p>Adicione procedimentos</p>
        </div>
      )}
      <div className="list-procedures">
        {(procedimentos || []).map((procedimento, i) => {
          return (
            <div
              key={"procedimento_" + i + "_" + procedimento.procedimento.id}
              className="item flex fw-w gap-2"
            >
              <div className="col-12 flex gap-15">
                <span className="f600-18 mt-5 span-2">
                  {" "}
                  {procedimento.procedimento.codigo}{" "}
                </span>
                <span className="f400-18 mt-5 span-9">
                  {" "}
                  {procedimento.procedimento.nome.toUpperCase()}{" "}
                </span>
                {procedimento.procedimento.dut && (
                  <span>
                    <a
                      href={`https://saasto.s3.sa-east-1.amazonaws.com/dut/${procedimento.procedimento.dut}.pdf`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i
                        className="icon icon-dut i-25"
                        title="Ver diretriz de utilização (DUT)"
                      />
                    </a>
                  </span>
                )}
                <div className="span-1">
                  {(form.id_processo_etapa === "12" ||
                    (([2, 6, 8].includes(parseInt(form.id_processo_etapa))) && ([1, 5, 8].includes(parseInt(config?.user.tipo)))
                    )) && (
                      <div className="col-12 grid gap-5 w-100 visible-desktop">
                        <span
                          className="pointer float-right"
                          onClick={(e) => removerProcedimento(e, i)}
                        >
                          <i
                            className="icon icon-trash watermelon i-25"
                            title="Excluir este procedimento"
                          />
                        </span>
                      </div>
                    )}
                </div>
              </div>
              <div className="col-12 grid ac-fs sm-gap">
                <div className="span-3 pt-10">
                  <InputForm
                    label="Quantidade solicitada"
                    name={"procedimentos." + i + ".quantidade_solicitada"}
                    type="number"
                    value={procedimento.data?.quantidade_solicitada}
                    model={(name, value) => {
                      let inputValue =
                        value !== ""
                          ? Math.max(1, Math.min(max, Number(parseInt(value))))
                          : "";
                      procedimentos[i].data.quantidade_solicitada = inputValue;
                      form.procedimentos = procedimentos;
                      setModel("procedimentos", procedimentos);
                      setProcedimentos([...procedimentos]);
                      calculaTotal();
                    }}
                    disabled={!permitirEdicao || config.user.tipo === "6"}
                  />
                </div>
                <div className="span-3 pt-10">
                  {parseInt(form.id_processo_tipo) !== 1 && (
                    <InputForm
                      label="Quantidade autorizada"
                      name={"procedimentos." + i + ".quantidade_aprovada"}
                      type="number"
                      value={procedimento.data?.quantidade_aprovada}
                      model={(name, value) => {
                        let inputValue =
                          value !== ""
                            ? Math.max(
                              min,
                              Math.min(
                                procedimento.data?.quantidade_solicitada,
                                Number(parseInt(value))
                              )
                            )
                            : "";
                        procedimentos[i].data.quantidade_aprovada = inputValue;
                        setProcedimentos([...procedimentos]);

                        // Limpa motivo divergência
                        const updatedProcedimentos = [...procedimentos];
                        updatedProcedimentos[i].data.id_processo_motivo_divergencia = '';
                        setProcedimentos(updatedProcedimentos);
                      }}
                      disabled={!permitirEdicao || config.user.tipo === "6"}
                    />
                  )}
                </div>

                <div className="span-3 pt-10 text-center">
                  <div className="group-input">
                    <label className="f300-14 black-text">
                      Possui cobertura?
                      <br />
                      <span className="fn-10 text-center">
                        (ROL e/ou contrato)
                      </span>
                    </label>
                  </div>
                  <ToggleSwitch
                    name={"rol_" + procedimento.id}
                    value={procedimento.data?.rol}
                    texto={["Sim", "Não"]}
                    model={(name, value) => {
                      procedimentos[i].data.rol = value ? 1 : 0;
                      setProcedimentos([...procedimentos]);
                    }}
                    disabled={!permitirEdicao || config.user.tipo === "6"}
                  />
                </div>
                {config.user.tipo !== "6" && (
                  <div className="span-3 pt-10">
                    <InputForm
                      label={`Valor unitário`}
                      name={"procedimentos." + i + ".valor_unitario"}
                      type="decimal"
                      value={procedimento.data?.valor_unitario}
                      model={(name, value) => {
                        let inputValue = value !== "" ? value : 0;
                        procedimentos[i].data.valor_unitario = inputValue;
                        form.procedimentos = procedimentos;
                        setModel("procedimentos", procedimentos);
                        setProcedimentos([...procedimentos]);
                        calculaTotal();
                      }}
                      disabled={!permitirEdicao || config.user.tipo === "6"}
                    />
                  </div>
                )}
              </div>

              <div className="col-12 grid ac-fs sm-gap">
                <div className="span-4 pt-10">
                  {parseInt(form.id_processo_tipo) !== 1 && (
                    <InputForm
                      label="Motivo de divergência"
                      value={procedimento.data?.id_processo_motivo_divergencia}
                      name={
                        "procedimentos." + i + ".id_processo_motivo_divergencia"
                      }
                      model={(name, value) => {
                        procedimentos[i].data.id_processo_motivo_divergencia = value;
                        setProcedimentos([...procedimentos]);
                      }}
                      type="select"
                      options={
                        procedimento.data?.quantidade_aprovada ===
                          procedimento.data?.quantidade_solicitada
                          ? options.motivosDeDivergenciaPDivergencia
                          : options.motivosDeDivergenciaP
                      }
                      disabled={!permitirEdicao || config.user.tipo === "6"}
                      required={true}
                      error={
                        validacao[
                        "procedimentos." +
                        i +
                        ".id_processo_motivo_divergencia"
                        ]
                      }
                    />
                  )}
                </div>
                <div className="span-8 pt-10">
                  {parseInt(form.id_processo_tipo) !== 1 && (
                    <InputForm
                      label="Justificativa"
                      value={procedimento.data?.observacao}
                      name={"procedimentos." + i + ".observacao"}
                      type="textarea"
                      height={130}
                      maxLength={4096}
                      model={(name, value) => {
                        procedimentos[i].data.observacao = value;
                        setProcedimentos([...procedimentos]);
                      }}
                      {...(![11].includes(
                        parseInt(
                          procedimento.data?.id_processo_motivo_divergencia
                        )
                      )
                        ? { required: true }
                        : {})}
                      error={validacao["procedimentos." + i + ".observacao"]}
                      disabled={!permitirEdicao || config.user.tipo === "6"}
                    />
                  )}
                </div>
              </div>
              {![11].includes(
                parseInt(procedimento.data?.id_processo_motivo_divergencia)
              ) && (
                  <div className="col-12 grid sm-gap">
                    <div className="span-3 pt-10">
                      {parseInt(form.id_processo_etapa) !== 12 && (
                        <InputForm
                          label="Quantidade pós análise"
                          name={"procedimentos." + i + ".quantidade_pos_analise"}
                          type="number"
                          value={procedimento.data?.quantidade_pos_analise}
                          model={(name, value) => {
                            let inputValue =
                              value !== ""
                                ? Math.max(
                                  procedimento.data?.quantidade_aprovada,
                                  Math.min(
                                    procedimento.data?.quantidade_solicitada,
                                    Number(parseInt(value))
                                  )
                                )
                                : "";
                            procedimentos[i].data.quantidade_pos_analise =
                              inputValue;
                            setProcedimentos([...procedimentos]);
                          }}
                          disabled={
                            !config.temPermissao(
                              "processo:processo_eventos:cadastrar"
                            ) ||
                            (config.user.tipo === "6" &&
                              form.id_processo_etapa !== "3") ||
                            (config.user.tipo === "5" &&
                              form.id_processo_etapa !== "10" &&
                              form.id_processo_etapa_status !== "32")
                          }
                        />
                      )}
                    </div>

                    <div className="span-9 pt-10">
                      {parseInt(form.id_processo_etapa) !== 12 && (
                        <InputForm
                          label="Justificativa pós análise"
                          value={procedimento.data?.justificativa_analise}
                          name={"procedimentos." + i + ".justificativa_analise"}
                          type="textarea"
                          height={130}
                          maxLength={4096}
                          model={(name, value) => {
                            procedimentos[i].data.justificativa_analise = value;
                            setProcedimentos([...procedimentos]);
                          }}
                          disabled={
                            !config.temPermissao(
                              "processo:processo_eventos:cadastrar"
                            ) ||
                            (config.user.tipo === "6" &&
                              form.id_processo_etapa !== "3") ||
                            (config.user.tipo === "5" &&
                              form.id_processo_etapa !== "10" &&
                              form.id_processo_etapa_status !== "32")
                          }
                        />
                      )}
                    </div>
                  </div>
                )}
              {parseInt(form.id_processo_etapa) === 12 && (
                <div className="col-12 grid gap-5 w-100 visible-mobile">
                  <span
                    className="pointer float-right"
                    onClick={(e) => removerProcedimento(e, i)}
                  >
                    <i className="icon icon-trash watermelon i-25" />
                  </span>
                </div>
              )}
            </div>
          );
        })}
      </div>

      {form.id_processo_etapa === "12" ||
        config?.user.tipo === "1" ||
        config?.user.tipo === "4" ||
        config?.user.tipo === "5" ||
        config?.user.tipo === "8" ? (
        <div className="form-container grid col-12 w-100 mt-80 mb-45">
          <InputForm
            label="Material ou medicamento"
            ref={materiaisRef}
            type="search"
            name="busca_materiais"
            className="span-5 personalize-search"
            onSearch={searchMateriais}
            model={setMaterial}
            limpar={true}
            searchCodigo={"codigo"}
            viewMore={true}
            childToParent={childToParentSecundary}
          />
        </div>
      ) : (
        <div className="group-input f400-18">
          <label className="f700-20 black-text mt-30 mb-20">
            <span className="marker" />
            Materiais e medicamentos:
          </label>
        </div>
      )}
      {!materiais.length && (
        <div className="empty-procedures w-100" style={{ height: "310px" }}>
          <p>Adicione materiais ou medicamentos</p>
        </div>
      )}
      <div className="list-procedures">
        {(materiais || []).map((material, i) => {
          return (
            <div
              key={"material_" + i + "_" + material.material.id}
              className="item flex fw-w gap-2"
            >
              <div className="col-12 flex gap-15">
                <span className="f600-18 mt-5 span-2">
                  {" "}
                  {material.material.codigo}{" "}
                </span>
                <span className="f400-18 mt-5 span-9">
                  {" "}
                  {material.material.nome.toUpperCase()}{" "}
                </span>
                <div className="span-1">
                  {(form.id_processo_etapa === "12" ||
                    (([2, 6, 8].includes(parseInt(form.id_processo_etapa))) && ([1, 5, 8].includes(parseInt(config?.user.tipo)))
                    )) && (
                      <div className="col-12 grid gap-5 w-100 visible-desktop">
                        <span
                          className="pointer float-right"
                          onClick={(e) => removerMaterial(e, i)}
                        >
                          <i
                            className="icon icon-trash watermelon i-25"
                            title="Excluir este material"
                          />
                        </span>
                      </div>
                    )}
                </div>
              </div>
              <div className="col-12 grid sm-gap">
                <div className="span-3 pt-10">
                  <InputForm
                    label="Quantidade solicitada"
                    name={"materiais." + i + ".quantidade_solicitada"}
                    type="number"
                    value={material.data?.quantidade_solicitada}
                    model={(name, value) => {
                      let inputValue =
                        value !== ""
                          ? Math.max(1, Math.min(max, Number(parseInt(value))))
                          : "";
                      materiais[i].data.quantidade_solicitada = inputValue;
                      form.materiais = materiais;
                      setMateriais([...materiais]);
                      setModel("materiais", materiais);
                      calculaTotal();
                    }}
                    disabled={!permitirEdicao || config.user.tipo === "6"}
                  />
                </div>
                <div className="span-3 pt-10">
                  {parseInt(form.id_processo_tipo) !== 1 && (
                    <InputForm
                      label="Quantidade autorizada"
                      name={"materiais." + i + ".quantidade_aprovada"}
                      type="number"
                      value={material.data?.quantidade_aprovada}
                      model={(name, value) => {
                        let inputValue =
                          value !== ""
                            ? Math.max(
                              min,
                              Math.min(
                                material.data?.quantidade_solicitada,
                                Number(parseInt(value))
                              )
                            )
                            : "";
                        materiais[i].data.quantidade_aprovada = inputValue;
                        setMateriais([...materiais]);

                        // Limpa motivo divergência
                        const updatedMateriais = [...materiais];
                        updatedMateriais[i].data.id_processo_motivo_divergencia = '';
                        setMateriais(updatedMateriais);
                      }}
                      disabled={!permitirEdicao || config.user.tipo === "6"}
                    />
                  )}
                </div>
                {config.user.tipo !== "6" && (
                  <div className="span-3 pt-10">
                    <InputForm
                      label="Valor unitário"
                      name={"materiais." + i + ".valor_unitario"}
                      type="decimal"
                      value={material.data?.valor_unitario}
                      model={(name, value) => {
                        let inputValue = value !== "" ? value : 0;
                        materiais[i].data.valor_unitario = inputValue;
                        form.materiais = materiais;
                        setModel("materiais", materiais);
                        setMateriais([...materiais]);
                        calculaTotal();
                      }}
                      disabled={!permitirEdicao || config.user.tipo === "6"}
                    />
                  </div>
                )}
              </div>

              <div className="col-12 grid ac-fs sm-gap">
                <div className="span-4 pt-10">
                  {parseInt(form.id_processo_tipo) !== 1 && (
                    <InputForm
                      label="Motivo de divergência"
                      value={material.data?.id_processo_motivo_divergencia}
                      name={
                        "materiais." + i + ".id_processo_motivo_divergencia"
                      }
                      model={(name, value) => {
                        materiais[i].data.id_processo_motivo_divergencia =
                          value;
                        setMateriais([...materiais]);
                      }}
                      type="select"
                      options={
                        material.data?.quantidade_aprovada ===
                          material.data?.quantidade_solicitada
                          ? options.motivosDeDivergenciaMDivergencia
                          : options.motivosDeDivergenciaM
                      }
                      disabled={!permitirEdicao || config.user.tipo === "6"}
                      required={true}
                      error={
                        validacao[
                        "materiais." + i + ".id_processo_motivo_divergencia"
                        ]
                      }
                    />
                  )}
                </div>

                <div className="span-8 pt-10">
                  {parseInt(form.id_processo_tipo) !== 1 && (
                    <InputForm
                      label="Justificativa"
                      value={material.data?.observacao}
                      name={"materiais." + i + ".observacao"}
                      type="textarea"
                      height={130}
                      maxLength={4096}
                      model={(name, value) => {
                        materiais[i].data.observacao = value;
                        setMateriais([...materiais]);
                      }}
                      {...(![21].includes(
                        parseInt(material.data?.id_processo_motivo_divergencia)
                      )
                        ? { required: true }
                        : {})}
                      error={validacao["materiais." + i + ".observacao"]}
                      disabled={!permitirEdicao || config.user.tipo === "6"}
                    />
                  )}
                </div>
              </div>
              {![21].includes(
                parseInt(material.data?.id_processo_motivo_divergencia)
              ) && (
                  <div className="col-12 grid sm-gap">
                    <div className="span-3 pt-10">
                      {parseInt(form.id_processo_etapa) !== 12 && (
                        <InputForm
                          label="Quantidade pós análise"
                          name={"materiais." + i + ".quantidade_pos_analise"}
                          type="number"
                          value={material.data?.quantidade_pos_analise}
                          model={(name, value) => {
                            let inputValue =
                              value !== ""
                                ? Math.max(
                                  material.data?.quantidade_aprovada,
                                  Math.min(
                                    material.data?.quantidade_solicitada,
                                    Number(parseInt(value))
                                  )
                                )
                                : "";
                            materiais[i].data.quantidade_pos_analise = inputValue;
                            setMateriais([...materiais]);
                          }}
                          disabled={
                            !config.temPermissao(
                              "processo:processo_eventos:cadastrar"
                            ) ||
                            (config.user.tipo === "6" &&
                              form.id_processo_etapa !== "3") ||
                            (config.user.tipo === "5" &&
                              form.id_processo_etapa !== "10" &&
                              form.id_processo_etapa_status !== "32")
                          }
                        />
                      )}
                    </div>
                    <div className="span-9 pt-10">
                      {parseInt(form.id_processo_etapa) !== 12 && (
                        <InputForm
                          label="Justificativa pós análise"
                          value={material.data?.justificativa_analise}
                          name={"materiais." + i + ".justificativa_analise"}
                          type="textarea"
                          height={130}
                          maxLength={4096}
                          model={(name, value) => {
                            materiais[i].data.justificativa_analise = value;
                            setMateriais([...materiais]);
                          }}
                          disabled={
                            !config.temPermissao(
                              "processo:processo_eventos:cadastrar"
                            ) ||
                            (config.user.tipo === "6" &&
                              form.id_processo_etapa !== "3") ||
                            (config.user.tipo === "5" &&
                              form.id_processo_etapa !== "10" &&
                              form.id_processo_etapa_status !== "32")
                          }
                        />
                      )}
                    </div>
                  </div>
                )}
              {parseInt(form.id_processo_etapa) === 12 && (
                <div className="col-12 grid gap-5 w-100 visible-mobile">
                  <span
                    className="pointer float-right"
                    onClick={(e) => removerMaterial(e, i)}
                  >
                    <i className="icon icon-trash watermelon i-25" />
                  </span>
                </div>
              )}
            </div>
          );
        })}
      </div>
      {config.user.tipo !== "6" && (
        <div className="span-12 flex mt-25 jc-fe">
          <span className="f700-20 text-right span-10 mt-10">Valor total:</span>{" "}
          <span className="f400-20 ml-10 span-2">
            <InputForm
              name="valor_total"
              type="decimal"
              value={form?.valor_total}
              model={(name, value) => {
                value = value !== "" ? value : 0;
                setModel(name, value);
              }}
              disabled={!permitirEdicao || config.user.tipo === "6"}
            />
          </span>
        </div>
      )}
      {form.id_processo_tipo === "1" && (
        <InputForm
          label="Solicitação da operadora"
          type="textarea"
          height={300}
          maxLength={5000}
          className="span-4"
          value={form.observacao}
          name="observacao"
          model={setModel}
          disabled={
            form.id_processo_etapa !== "12" &&
            config.user.tipo !== "2" &&
            config.user.tipo !== "3" &&
            form.id_processo_etapa !== "2" &&
            (config.user.tipo !== "4" || config.user.tipo !== "8") &&
            form.id_processo_etapa !== "10" &&
            config.user.tipo !== "5"
          }
        />
      )}
      {modoEdicao &&
        form.id_processo_etapa !== "4" &&
        form.id_processo_etapa !== "5" && (
          <div className="form-rodape mt-70 mb-30">
            <button
              className="btn btn-outline-primary f700-16"
              onClick={cancel}
            >
              Cancelar
            </button>
            <button
              className="btn btn-primary f700-16"
              onClick={(e) => salvar(e, 2)}
              disabled={updateStatus}
            >
              {updateStatus ? <Loading /> : "Salvar"}
            </button>
          </div>
        )}
    </div>
  );
}
