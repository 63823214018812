import React, { useEffect, useState } from 'react';
import ReactPaginate from "react-paginate";
import Layout from "../../components/Layout.js";
import '../../assets/styles/pages/Usuarios.scss'
import 'pure-css-loader/src/loader-default.sass';
import business from "../../services/business.js";
import layout from '../../lib/layout.js';
//import config from '../../config/config.js';
import tools from '../../lib/tools.js';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import Loading from '../../components/Loading.js';

export default function Financeiro() {

    //let user = config.user;
    document.title = 'säästo';
    layout.openMenu('/usuarios', true);
    const [pager, setPager] = useState({
        page: 1,
        total_pages: 0,
        total_rows: 0,
    });

    const [loaderPdf, setLoaderPdf] = useState(false);
    const [loaderCsv, setLoaderCsv] = useState(false);
    const [processos, setProcessos] = useState([]);
    /*   const [filtros] = useState({
          id_operadora: (!config.isAdmin()) ? user?.id_operadora : '',
          desc_operadora: (!config.isAdmin()) ? user?.desc_operadora : ''
      });
   */
    const handlePageClick = (e) => {
        getSearch(e.selected + 1);
    };

    const dataExport = async () => {
        return await business.exportRelatorioFinanceiro();
    }

    const getSearch = async (page = null, loading = true) => {
        setLoading(loading);
        let processos = await business.getRelatorioFinanceiro((page === null) ? pager.page : page, 10, {});

        setProcessos(processos?.response || [])
        if (processos?.response && pager.page && processos?.pager?.paginas < pager.page) {
            pager.page = 1;
            return getSearch();
        }
        pager.page = processos?.pager?.pagina;
        setPager({
            page: processos?.pager?.pagina,
            total_pages: processos?.pager?.paginas,
            total_rows: processos?.pager?.total
        });
        setLoading(false);
        setSearchLoading(false);
    }

    // eslint-disable-next-line 
    const [searchLoading, setSearchLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            await getSearch();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const exportToPdf = async () => {
        setLoaderPdf(true);
        let data = (await dataExport())?.response || [];

        const valoresBodyPdf = data.map(elemento => {
            const valores = Object.values(elemento);

            if (valores[1]) {
                valores[1] = tools.formatDate(valores[1]);
            }

            if (valores[9]) {
                valores[9] = 'R$ ' + valores[9];
            }

            if (valores[10]) {
                valores[10] = 'R$ ' + valores[10];
            }

            return valores;

        });

        const doc = new jsPDF({ orientation: 'l' });
        autoTable(doc, { html: '#my-table' })
        const header = [['Protocolo'], ['Data conclusão'], ['Tipo de processo'], ['Operadora'], ['Plano'], ['Especialista'], ['Especialista CRM/CRO'], ['Status'], ['Conclusão'], ['Tipo análise'], ['Valor operadora'], ['Valor especialista']]
        const tableStartY = 10;
        autoTable(doc, {
            head: [header],
            body: valoresBodyPdf,
            startY: tableStartY,
            styles: { cellPadding: 2, fontSize: 6 },
            columnStyles: { 0: { halign: 'center' } },
            margin: { top: 10 },
        });
        doc.save('relatorio-financeiro.pdf');
        setLoaderPdf(false);
    };

    const exportToCsv = async () => {
        setLoaderCsv(true);
        let filename = 'relatorio-financeiro.csv';
        let data = (await dataExport())?.response || [];

        const options = { style: 'decimal', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 }
        const numberFormatter = new Intl.NumberFormat('pt-BR', options);

        const processValue = (value) => {
            if (value === null) {
                return '';
            } else if (value instanceof Date) {
                return value.toLocaleString();
            } else if (typeof value === 'number') {
                return numberFormatter.format(value);
            } else if (typeof value === 'string') {
                return `"${value.replace(/"/g, '""')}"`;
            }
            return value;
        };

        const csvRows = [Object.keys(data[0]).map(processValue)]; // Cabeçalho
        data.forEach((objeto) => {
            csvRows.push(Object.values(objeto).map(processValue));
        });

        const csvFile = csvRows.map((row) => row.join(';')).join('\n');
        const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csvFile], { type: 'text/csv;charset=utf-8;' });

        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, filename);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
        setLoaderCsv(false);
    };

    return (
        <Layout className="view-listagem">
            <p className="page-title">Relatórios</p>
            <h2 className="title flex jc-sb ai-fe black-text mb-40 gap-10">
                Financeiro
            </h2>
            <div className="flex jc-sb ai-fc mb-60">
                <div className="container-search-filter flex ai-fc jc-sb gap-25">
                </div>
                <div className="flex ai-fc gap-30 desktop">
                    <button className="btn btn-primary f700-16" onClick={exportToPdf} disabled={loaderPdf || loaderCsv}>
                        {loaderPdf ? <Loading /> : <i className="icon icon-print i-25" />} <span className="pl-5">Imprimir</span>
                    </button>
                    <button className="btn btn-primary f700-16" onClick={exportToCsv} disabled={loaderCsv || loaderPdf}>
                        {loaderCsv ? <Loading /> : <i className="icon icon-document i-25" />}<span className="pl-5">Exportar</span>
                    </button>
                </div>
            </div>
            {loading ? <div className="loader loader-default is-active" /> : (<div style={{ overflowX: 'scroll' }}>
                <div className="table-mobile">
                    {processos.map((p, i) => {
                        return <div className="item pointer" key={`mobile-${i}`}>
                            <div>
                                <div className="fn-16 linha flex jc-sb">
                                    Protocolo <span>{p.id_protocolo}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Data de conclusão <span>{p.data_conclusao ? tools.formatDate(p.data_conclusao) : '-'}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Tipo de processo <span>{p.desc_processo_tipo}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Operadora <span>{p.desc_operadora}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Plano <span>{p.plano ? p.plano : '-'}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Especialista <span>{p.nome_desempatador ? p.nome_desempatador : '-'}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    CRM/CRO <span>{p.crm_cro ? p.crm_cro : '-'}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Status <span>{p.desc_processo_etapa_status}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Conclusão <span>{p.processo_conclusao}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Tipo análise <span>{p.tipo_analise}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">{p.valor_operadora ? tools.formatCurrency(p.valor_operadora) : '-'}  </div>

                                <div className="fn-16 linha flex jc-sb">{p.valor_desempatador ? tools.formatCurrency(p.valor_desempatador) : '-'}  </div>

                                <div className="fn-16 linha flex jc-sb">
                                    Lucro Bruto <span>{p.valor_operadora - p.valor_desempatador}</span>
                                </div>
                            </div>
                            <div className="w-100 flex jc-fc">
                                -
                            </div>
                        </div>
                    })}
                    {(!processos || !processos?.length) && <div className="item pointer" key={`mobile-0`}>
                        Nenhum resultado encontrado.
                    </div>}
                </div>
                <table className="w-100 table-desktop" style={{ minWidth: 'auto' }}>
                    <thead className="f300-16">
                        <tr>
                            <th className="text-left bold">Protocolo</th>
                            <th className="text-center bold">Data de conclusão</th>
                            <th className="text-left bold">Tipo de processo</th>
                            <th className="text-left bold">Operadora</th>
                            <th className="text-left bold">Plano</th>
                            <th className="text-left bold">Especialista</th>
                            <th className="text-left bold">CRM/CRO</th>
                            <th className="text-left bold">Status</th>
                            <th className="text-left bold">Conclusão</th>
                            <th className="text-left bold">Tipo análise</th>
                            <th className="text-center bold">Valor operadora</th>
                            <th className="text-center bold">Valor especialista</th>
                            <th className="text-left bold">Lucro Bruto</th>
                        </tr>
                    </thead>
                    <tbody>
                        {processos.map((p, i) => {
                            return <tr key={`desktop-${i}`} className="hover-highlight">
                                <td className="fn-16 text-left">{p.id_protocolo}</td>
                                <td className="fn-16 text-center">{p.data_conclusao ? tools.formatDate(p.data_conclusao) : '-'}</td>
                                <td className="fn-16 ">{p.desc_processo_tipo}</td>
                                <td className="fn-16 ">{p.desc_operadora}</td>
                                <td className="fn-16 ">{p.plano ? p.plano : '-'}</td>
                                <td className="fn-16 ">{p.nome_desempatador ? p.nome_desempatador : '-'}</td>
                                <td className="fn-16 ">{p.crm_cro ? p.crm_cro : '-'}</td>
                                <td className="fn-16 ">{p.desc_processo_etapa_status}</td>
                                <td className="fn-16" style={{ maxWidth: '200px' }}>{p.processo_conclusao}</td>
                                <td className="fn-16 ">{p.tipo_analise}</td>
                                <td className="fn-16 text-center">{p.valor_operadora ? tools.formatCurrency(p.valor_operadora) : '-'}</td>
                                <td className="fn-16 text-center">{p.valor_desempatador ? tools.formatCurrency(p.valor_desempatador) : '-'}</td>
                                <td className="fn-16 text-center" style={{ minWidth: '120px', color: '#24BE34' }}>
                                    <strong>{tools.formatCurrency(p.valor_operadora - p.valor_desempatador)}</strong>
                                </td>
                            </tr>
                        })}
                        {(!processos || !processos?.length) && <tr>
                            <td colSpan={7}>Nenhum resultado encontrado.</td>
                        </tr>}
                    </tbody>
                </table>
                <div className="component pagination">
                    <div className="pagination-container mt-30">
                        {pager.total_pages < 2 ? '' : <ReactPaginate
                            previousLabel="«"
                            nextLabel="»"
                            breakLabel="..."
                            breakClassName="break-me"
                            page={pager.page - 1 ?? 0}
                            forcePage={pager.page - 1 ?? 0}
                            pageCount={pager.total_pages}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName="pagination"
                            subContainerClassName="pages pagination"
                            activeClassName="active" />}
                    </div>
                </div>
            </div>)
            }

        </Layout >
    );
}

