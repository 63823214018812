import moment from "moment/moment.js";
import 'moment/locale/pt-br';
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import ComAberturaJuntaBNF from "../components/Emails/ComAberturaJuntaBNF.js";
import ComAberturaJuntaPA from "../components/Emails/ComAberturaJuntaPA.js";
import ComDecisaoJuntaBNF from "../components/Emails/ComDecisaoJuntaBNF.js";
import ComDecisaoJuntaPA from "../components/Emails/ComDecisaoJuntaPA.js";
import ComEscolhaAbstencaoBNF from "../components/Emails/ComEscolhaAbstencaoBNF.js";
import ComEscolhaAbstencaoPA from "../components/Emails/ComEscolhaAbstencaoPA.js";
import ComEscolhaBNF from "../components/Emails/ComEscolhaBNF.js";
import ComEscolhaPA from "../components/Emails/ComEscolhaPA.js";
import ParOpiniaoEspecializada from "../components/Emails/ParOpiniaoEspecializada.js";
import ParTecnicoConclusivo from "../components/Emails/ParTecnicoConclusivo.js";
import SolExamesComplementaresBNF from "../components/Emails/SolExamesComplementaresBNF.js";
import SolExamesComplementaresOPS from "../components/Emails/SolExamesComplementaresOPS.js";
import ValConsensoParcial from "../components/Emails/ValConsensoParcial.js";
import business from "../services/business.js";
import tools from "../lib/tools.js";
import Loading from "../components/Loading.js";
import { useParams } from "react-router-dom";
import ComRecusaConsensoParcialPA from "../components/Emails/ComRecusaConsensoParcialPA.js";
import ComRecusaConsensoParcialPAConsensoPre from "../components/Emails/ComRecusaConsensoParcialPAConsensoPre.js";
import ComConsensoTecnicoOPS from "../components/Emails/ComConsensoTecnicoOPS.js";
import ComConsensoTecnicoBNF from "../components/Emails/ComConsensoTecnicoBNF.js";
import ComConsensoTecnicoPA from "../components/Emails/ComConsensoTecnicoPA.js";

export default function EmailCheck() {

    let { id } = useParams();

    const [notify, setNotify] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function getNotifyInfo() {
            setLoading(true);
            let response = await business.getNotificacaoPorID(id);
            setNotify(response);
            setLoading(false);
        }
        getNotifyInfo();
    }, [id, setNotify]);

    let item = notify?.processo?.disparo;

    const data = {
        protocolo: item?.id_protocolo,
        operadora_nome: item?.desc_operadora?.toUpperCase(),
        operadora_endereco: item?.endereco_operadora?.logradouro?.toUpperCase() + ', ' + item?.endereco_operadora?.numero + ', ' + item?.endereco_operadora?.bairro?.toUpperCase() + ', ' + item?.endereco_operadora?.cidade?.toUpperCase() + ' - ' + item?.endereco_operadora?.uf,
        operadora_telefone: item?.endereco_operadora?.telefone.replace(/(\d{2})(\d{4})(\d{4,5})$/, "($1) $2-$3") || 'Telefone indisponível',
        operadora_imagem: item?.endereco_operadora?.imagem || item?.operadora?.imagem,
        data_disparo: moment(notify?.data_disparo).format('LL'),
        profissional_nome: item?.profissional?.nome?.toUpperCase(),
        profissional_crmcro: item?.profissional?.crm,
        profissional_uf: item?.profissional?.uf,
        profissional_telefone: item?.profissional?.telefone.replace(/(\d{2})(\d{4})(\d{4,5})$/, "($1) $2-$3"),
        data_atendimento: moment(item?.data_atendimento).format('DD/MM/YYYY'),
        beneficiario_nome: item?.beneficiario?.nome?.toUpperCase(),
        beneficiario_cpf: item?.beneficiario?.cpf,
        beneficiario_sexo: item?.beneficiario?.sexo === 'M' ? 'MASCULINO' : 'FEMININO',
        beneficiario_guia: item?.guia,
        beneficiario_nascimento: item?.beneficiario?.nascimento,
        beneficiario_idade: tools?.calcularIdade(item?.beneficiario?.nascimento),
        auditor_nome: item?.auditor?.nome?.toUpperCase(),
        auditor_crmcro: item?.auditor?.crm,
        auditor_uf: item?.auditor?.uf,
        assistente_responsavel_nome: item?.responsaveis?.nome_assistente?.toUpperCase(),
        enfermeira_responsavel_nome: item?.responsaveis?.nome_enfermeira?.toUpperCase(),
        desempatador_responsavel_nome: item?.responsaveis?.nome_desempatador?.toUpperCase(),
        desempatador_responsavel_crmcro: item?.responsaveis?.crm_desempatador || item?.responsaveis?.cro_desempatador,
        desempatador_responsavel_uf: item?.responsaveis?.crm_uf_desempatador || item?.responsaveis?.cro_uf_desempatador,
        desempatador_responsavel_laudo: item?.texto_parecer,
        data_parecer: moment(item?.data_validacao).format('LL'),
        data_parecer_min: moment(item?.data_validacao).format('DD/MM/YYYY'),
        procedimentos: (item?.procedimentos || []).map((p) => {
            return {
                id: p.id_procedimento,
                codigo: p?.codigo || '-',
                descricao: p?.desc_procedimento,
                quantidade_solicitada: p?.quantidade_solicitada || 0,
                quantidade_autorizada: p?.quantidade_aprovada || 0,
                quantidade_pos_analise: p?.quantidade_pos_analise || 0,
                id_divergencia: p?.id_processo_motivo_divergencia || '-',
                divergencia: p?.desc_processo_motivo_divergencia || '-',
                justificativa: p?.observacao || '-',
                justificativa_pos_analise: p?.justificativa_analise || '-'
            };
        }),
        materiais: (item?.materiais || []).map((m) => {
            return {
                id: m?.id_material,
                descricao: m?.desc_material,
                quantidade_solicitada: m?.quantidade_solicitada || 0,
                quantidade_autorizada: m?.quantidade_aprovada || 0,
                quantidade_pos_analise: m?.quantidade_pos_analise || 0,
                id_divergencia: m?.id_processo_motivo_divergencia || '-',
                divergencia: m?.desc_processo_motivo_divergencia || '-',
                justificativa: m?.observacao || '-',
                justificativa_pos_analise: m?.justificativa_analise || '-'
            };
        }),
        desempatadores: item?.desempatadores,
        id_desempatador: item?.responsaveis?.id_desempatador || 0,
        notificacao_envio: item?.notificacao_envio || {},
        exames_complementares: [],
        conclusao: item?.processo_conclusao
    }

    const dataNotify = {
        texto: notify?.desc_acao,
        data_disparo: moment(notify?.data_disparo).format('DD/MM/YYYY HH:mm:ss'),
        data_leitura: notify?.data_leitura && moment(notify?.data_leitura).format('DD/MM/YYYY HH:mm:ss'),
        codigo: notify?.id + item?.id_protocolo,
        canal: notify?.desc_canal,
        receptorBNF: notify?.id_beneficiario,
        receptorPA: notify?.id_profissional,
        receptorOPS: notify?.id_operadora,
        canalBNF: (notify?.id_notificacao_canal === '2' ? item?.beneficiario?.email : item?.beneficiario?.telefone.replace(/(\d{2})(\d{4})(\d{4,5})$/, "($1) $2-$3")),
        canalPA: (notify?.id_notificacao_canal === '2' ? item?.profissional?.email : item?.profissional?.telefone.replace(/(\d{2})(\d{4})(\d{4,5})$/, "($1) $2-$3")),
        canalOPS: (notify?.id_notificacao_canal === '2' ? item?.endereco_operadora?.email : item?.endereco_operadora?.telefone.replace(/(\d{2})(\d{4})(\d{4,5})$/, "($1) $2-$3")),
        disparo: (notify?.id_notificacao_canal === '2' ? 'sistema@saasto.com.br' : '(48) 9199-9368 (Whatsapp säästö)'),
        ip: notify?.ip,
        navegador: notify?.navegador,
        sistema_operacional: notify?.sistema_operacional,
    }

    return (
        <>
            {loading ? (<div className="p-30 flex"><Loading /> <span className="pl-10">Carregando...</span></div>) : <div>
                {notify.id_notificacao_conteudo === '1' && <ComAberturaJuntaBNF resp={data} notify={dataNotify} viewHeader={true} />}
                {notify.id_notificacao_conteudo === '2' && <ComAberturaJuntaPA resp={data} notify={dataNotify} viewHeader={true} disabled={true} />}
                {notify.id_notificacao_conteudo === '3' && <ComConsensoTecnicoOPS resp={data} notify={dataNotify} viewHeader={true} />}
                {notify.id_notificacao_conteudo === '4' && <ComDecisaoJuntaBNF resp={data} notify={dataNotify} viewHeader={true} />}
                {notify.id_notificacao_conteudo === '5' && <ComDecisaoJuntaPA resp={data} notify={dataNotify} viewHeader={true} />}
                {notify.id_notificacao_conteudo === '6' && <ComEscolhaAbstencaoBNF resp={data} notify={dataNotify} viewHeader={true} />}
                {notify.id_notificacao_conteudo === '7' && <ComEscolhaAbstencaoPA resp={data} notify={dataNotify} viewHeader={true} />}
                {notify.id_notificacao_conteudo === '8' && <ComEscolhaBNF resp={data} notify={dataNotify} viewHeader={true} />}
                {notify.id_notificacao_conteudo === '9' && <ComEscolhaPA resp={data} notify={dataNotify} viewHeader={true} />}
                {notify.id_notificacao_conteudo === '10' && <ParOpiniaoEspecializada resp={data} notify={dataNotify} />}
                {notify.id_notificacao_conteudo === '11' && <ParTecnicoConclusivo resp={data} notify={dataNotify} viewHeader={true} />}
                {notify.id_notificacao_conteudo === '13' && <SolExamesComplementaresBNF resp={data} notify={dataNotify} viewHeader={true} />}
                {notify.id_notificacao_conteudo === '14' && <SolExamesComplementaresOPS resp={data} notify={dataNotify} viewHeader={true} />}
                {notify.id_notificacao_conteudo === '15' && <ValConsensoParcial resp={data} notify={dataNotify} viewHeader={true} />}
                {notify.id_notificacao_conteudo === '17' && <ComRecusaConsensoParcialPA resp={data} notify={dataNotify} viewHeader={true} />}
                {notify.id_notificacao_conteudo === '18' && <ComRecusaConsensoParcialPAConsensoPre resp={data} notify={dataNotify} viewHeader={true} />}
                {notify.id_notificacao_conteudo === '19' && <ComConsensoTecnicoBNF resp={data} notify={dataNotify} viewHeader={true} />}
                {notify.id_notificacao_conteudo === '20' && <ComConsensoTecnicoPA resp={data} notify={dataNotify} viewHeader={true} />}
            </div>}
        </>
    );
}