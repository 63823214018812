import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import Anexos from "../../components/Processo/Abas/Anexos.js";
import DadosGerais from "../../components/Processo/Abas/DadosGerais.js";
import DadosProcesso from "../../components/Processo/Abas/DadosProcesso.js";
import Layout from "../../components/Layout.js";
import Stages from "../../components/Stages.js";
import "../../assets/styles/pages/Processo/Cadastro.scss";
import business from "../../services/business.js";
import Preloader from "../../components/Preloader.js";
import Loading from "../../components/Loading.js";
import layout from "../../lib/layout.js";
import tools from "../../lib/tools.js";
import config from "../../config/config.js";
import ModalAlert from "../../components/Modais/ModalAlert.js";

export default function CadProcesso({ name }) {
  const [startDate, setStartDate] = useState(config.dataHoraAtual());
  const [expireDate, setExpireDate] = useState("");
  const [ansDate, setAnsDate] = useState("");
  const [render, setRender] = useState(true);
  const [modalData, setModalData] = useState({});

  let user = config.user;
  document.title = 'säästo';

  const defaultValues = {
    id_operadora: (!config.isAdmin() ? user?.id_operadora : "") || "",
    desc_operadora: (!config.isAdmin() ? user?.desc_operadora : "") || "",
    id_plano: "",
    id_processo_tipo: "",
    id_processo_tipo_atendimento: "",
    jmo_video: 0,
    data_atendimento: "",
    inicio_processo: config.dataHoraAtual(),
    prestador: {
      cnpj: "",
      nome: "",
    },
    beneficiario: {
      cpf: "",
      nome: "",
      nascimento: "",
      sexo: "",
      telefone: "",
      telefone2: "",
      email: "",
      email2: "",
      cep: "",
      rua: "",
      numero: "",
      cidade: "",
      bairro: "",
      uf: "",
      carteirinha: "",
      complemento: "",
    },
    profissional: {
      uf: "",
      crm: "",
      nome: "",
      telefone: "",
      telefone2: "",
      email: "",
      email2: "",
      cep: "",
      rua: "",
      numero: "",
      cidade: "",
      bairro: "",
      endereco_uf: "",
      complemento: "",
    },
    auditor: {
      uf: "",
      crm: "",
      nome: "",
    },
    procedimentos: [],
    materiais: [],
    anexos: [],
    valor_total: 0,
  };

  layout.openMenu(`/${name}`);
  const navigate = useNavigate();
  const alert = useAlert();
  const [form, setForm] = useState(defaultValues);
  const [validacao, setValidacao] = useState({});
  const [updateStatus, setUpdateStatus] = useState(false);
  const [options, setOptions] = useState({});
  const [stages, setStages] = useState([
    {
      id: 1,
      label: "Dados Gerais",
      completed: false,
      validate: function (form) {
        return form?.id ? true : false;
      },
    },
    {
      id: 2,
      label: "Dados do Processo",
      completed: false,
      validate: function (form) {
        return form?.procedimentos?.length > 0 || form?.materiais?.length > 0
          ? true
          : false;
      },
    },
    {
      id: 3,
      label: "Anexos",
      completed: false,
      validate: function (form) {
        return form?.anexos?.length > 0 ? true : false;
      },
    },
  ]);

  useEffect(() => {
    async function getOptions() {
      if (options?.estados) {
        return;
      }

      options.estados = business.getEstados();
      options.sexos = business.getSexo();
      options.motivosDeDivergenciaP = business
        .getMotivosDeDivergenciaP()
        .filter((motivo) => motivo.active);
      options.motivosDeDivergenciaM = business
        .getMotivosDeDivergenciaM()
        .filter((motivo) => motivo.active);
      options.motivosDeDivergenciaPDivergencia = business
        .getMotivosDeDivergenciaPDivergencia()
        .filter((motivo) => motivo.active);
      options.motivosDeDivergenciaMDivergencia = business
        .getMotivosDeDivergenciaMDivergencia()
        .filter((motivo) => motivo.active);
      options.tipoAnalise = business.getTipoAnalise();
      options.planoBeneficiario = business.getPlanoBeneficiario();

      await business
        .getTiposProcesso()
        .then((l) => (options.tiposProcesso = l));
      await business.getTipoAnexo().then((l) => (options.tiposAnexo = l));
      await business
        .getTiposAtendimento()
        .then((l) => (options.tiposAtendimento = l));

      setOptions((prevState) => {
        return { ...prevState, ...options };
      });
    }
    getOptions();
  }, [options]);

  useEffect(() => {
    (async () => {
      let formSaved = JSON.parse(localStorage.getItem("process"));

      // auto fix (draft)
      if (formSaved?.id && !formSaved?.id_processo_etapa) {
        formSaved.id_processo_etapa = 12;
        formSaved.id_processo_etapa_status = 24;
      }

      setForm(formSaved?.id ? formSaved : defaultValues);
    })();
    // eslint-disable-next-line
  }, []);

  const focusInput = (name) => {
    if (!document.getElementsByName(name)?.length) {
      return;
    }
    var item = document.getElementsByName(name)[0]
    if (item) {
      item.focus()
    }
  }

  const salvar = async (e) => {
    const obrigatorios = [];

    document.querySelectorAll("main [name][required]").forEach((e) => {
      if (e.name && e.style.display !== "none") obrigatorios.push(e.name);
    });

    let validacao = {};
    let validated = true;
    let first = false;

    switch (stage) {
      case 1:
        obrigatorios.forEach((campo, index) => {
          let keys = campo.split(".");
          if (keys.length === 2) {
            if (
              typeof form[keys[0]][keys[1]] === "undefined" ||
              tools.isEmptyNullOrUndefined(form[keys[0]][keys[1]])
            ) {
              validacao[campo] = "Campo Obrigatório";
              validated = false;
              if (!first) {
                focusInput(campo);
                first = true;
              }
            } else if (["cpf"].includes(keys[1])) {
              let validCpf = tools.validarCPF(form[keys[0]][keys[1]]);
              if (!validCpf.valido) {
                validacao[campo] = validCpf.texto;
                validated = false;
                if (!first) {
                  focusInput(campo);
                  first = true;
                }
              }
            } else if (["email"].includes(keys[1])) {
              let validEmail = tools.validarEmail(form[keys[0]][keys[1]]);
              if (!validEmail) {
                validacao[campo] = "Digite um e-mail válido";
                validated = false;
                if (!first) {
                  focusInput(campo);
                  first = true;
                }
              }
            } else if (["telefone"].includes(keys[1])) {
              let validarTelefone = tools.validarTelefone(
                form[keys[0]][keys[1]]
              );
              if (!validarTelefone) {
                validacao[campo] = "Digite um telefone válido";
                validated = false;
                if (!first) {
                  focusInput(campo);
                  first = true;
                }
              }
            } else if (["nascimento"].includes(keys[1])) {
              let validarData = tools.validarData(
                form[keys[0]][keys[1]]
              );
              if (!validarData) {
                validacao[campo] = "Digite uma data válida.";
                validated = false;
              }
            }
          } else if (keys.length > 2) {
            if (
              typeof form[keys[0]][keys[1]]?.data[keys[2]] === "undefined" ||
              tools.isEmptyNullOrUndefined(
                form[keys[0]][keys[1]]?.data[keys[2]]
              )
            ) {
              validacao[campo] = "Campo Obrigatório";
              validated = false;
              if (!first) {
                focusInput(campo);
                first = true;
              }
            }
          } else if (
            typeof form[campo] == "undefined" ||
            tools.isEmptyNullOrUndefined(form[campo])
          ) {
            validacao[campo] = "Campo Obrigatório";
            validated = false;
            if (!first) {
              focusInput(campo);
              first = true;
            }
          }
        });

        setValidacao(validacao);

        if (validated) {
          let payload = { ...form };

          payload.procedimentos =
            form.procedimentos?.map((item_p) => {
              return item_p.data;
            }) || [];
          payload.materiais =
            form.materiais?.map((item_m) => {
              return item_m.data;
            }) || [];

          setUpdateStatus(true);

          const { response, errors } = await business.processo(
            payload,
            form?.id
          );
          if (errors && errors.length) {
            (errors || ["Ocorreu um erro ao tentar realizar ação"]).forEach(
              (e) => alert.error(e)
            );
          } else if (response?.id) {
            form.id = response.id;
            form.id_protocolo = response.id_protocolo;
            form.inicio_processo = response.inicio_processo;
            form.profissional.id = response.profissional.id;
            form.id_prestador = response.id_prestador;
            form.auditor.id = response.auditor.id;
            form.id_processo_etapa = response.id_processo_etapa;
            form.desc_processo_etapa = response.desc_processo_etapa;
            form.id_processo_etapa_status = response.id_processo_etapa_status;
            form.desc_processo_etapa_status =
              response.desc_processo_etapa_status;
            form.permissoes = response?.permissoes || [];

            localStorage.setItem("process", JSON.stringify(form));
            setStage(stage + 1);
            setForm(form);
          } else {
            alert.error("Ocorreu um erro ao salvar os dados");
          }

          setUpdateStatus(false);
        }

        break;
      case 2:
        let verificaQuantidadeValidaProcedimentos = (
          form?.procedimentos || []
        ).filter((item) => {
          return parseInt(item.data.quantidade_solicitada) === 0;
        });

        let verificaQuantidadeValidaMateriais = (form?.materiais || []).filter(
          (item) => {
            return parseInt(item.data.quantidade_solicitada) === 0;
          }
        );

        if (
          (!form?.materiais?.length && !form?.procedimentos?.length) ||
          verificaQuantidadeValidaProcedimentos?.length ||
          verificaQuantidadeValidaMateriais?.length
        ) {
          return setModalData({
            text: "Adicione ao menos um procedimento ou material.",
            show: true,
            buttonCancel: false,
            onAccept: async (e) => {
              setModalData({
                show: false,
              });
            },
          });
        }

        obrigatorios.forEach((campo) => {
          let keys = campo.split(".");
          let item = null;

          if (
            typeof form[keys[0]] === "object" &&
            typeof form[keys[0]].find === "function"
          ) {
            item = form[keys[0]].find(
              (obj, index) => index === parseInt(keys[1])
            );
          }

          if (
            !item ||
            typeof item?.data[keys[2]] === "undefined" ||
            tools.isEmptyNullOrUndefined(item.data[keys[2]])
          ) {
            validacao[campo] = "Campo Obrigatório";
            validated = false;
          }
        });

        setValidacao(validacao);

        if (validated) {
          let payload = { ...form };

          payload.procedimentos =
            form.procedimentos?.map((item_p) => {
              return item_p.data;
            }) || [];
          payload.materiais =
            form.materiais?.map((item_m) => {
              return item_m.data;
            }) || [];
          payload.valor_total = form.valor_total;

          setUpdateStatus(true);

          const { response, errors } = await business.processo(
            payload,
            form?.id
          );

          if (errors.length) {
            (errors || ["Ocorreu um erro ao tentar realizar ação"]).forEach(
              (e) => alert.error(e)
            );
          } else {
            form.id = response.id;
            localStorage.setItem("process", JSON.stringify(form));
            setStage(stage + 1);
          }

          setUpdateStatus(false);
        }
        break;
      case 3:
        let verificaPendentesEnvio = (form?.anexos || []).filter((item) => {
          return item.upload === true;
        });

        if (
          !form?.anexos ||
          !form?.anexos?.length ||
          verificaPendentesEnvio?.length
        ) {
          return setModalData({
            text: "Adicione ao menos um anexo ao processo.",
            show: true,
            buttonCancel: false,
            onAccept: async (e) => {
              setModalData({
                show: false,
              });
              localStorage.setItem("process", JSON.stringify(form));
            },
          });
        }

        obrigatorios.forEach((campo) => {
          let item = document.getElementsByName(campo);

          if (!item.length || tools.isEmptyNullOrUndefined(item[0].value)) {
            validacao[campo] = "Campo Obrigatório";
            validated = false;
          }
        });

        setValidacao(validacao);

        if (!validated) {
          return;
        }

        setModalData({
          title: "Enviar processo",
          text: "Você deseja enviar o processo " + form?.id + "?",
          show: true,
          onClose: async (e) => {
            setModalData({
              show: false,
            });
            localStorage.setItem("process", JSON.stringify(form));
          },
          onAccept: async (e) => {
            let payload = { ...form };

            payload.procedimentos =
              form.procedimentos?.map((item_p) => {
                return item_p.data;
              }) || [];
            payload.materiais =
              form.materiais?.map((item_m) => {
                return item_m.data;
              }) || [];
            payload.id_processo_etapa = 1;
            payload.id_processo_etapa_status = 1;

            setUpdateStatus(true);

            const { response, errors } = await business.processo(
              payload,
              form?.id
            );

            if (errors.length) {
              (errors || ["Ocorreu um erro ao tentar realizar ação"]).forEach(
                (e) => alert.error(e)
              );
            } else {
              if (response.slug) {
                clear();
                setTimeout(() => {
                  navigate("/" + response.slug + "/" + response.id);
                }, 500);
              }
            }

            setUpdateStatus(false);
            setModalData({
              show: false,
            });
          },
        });

        break;

      default:
    }
  };

  const clear = () => {
    localStorage.setItem("process", JSON.stringify({}));
  };

  const cancelar = async () => {
    if (form?.id) {
      return setModalData({
        text:
          "Deseja realmente cancelar o processo " + form?.id_protocolo + "?",
        show: true,
        buttonCancel: true,
        onAccept: async (e) => {
          e.preventDefault();
          form.id_processo_etapa = 5;
          form.id_processo_etapa_status = 8;
          let res = await business.cancelarProcesso(form?.id);
          if (res?.errors?.length) {
            res.errors.forEach((e) => alert.error(e));
          } else {
            clear();
            alert.success("O processo foi cancelado com sucesso!");
            form.id_processo_tipo = parseInt(form.id_processo_tipo);
            if (form.id_processo_tipo === 1) {
              navigate("/opiniao-especializada");
            } else if (form.id_processo_tipo === 2) {
              navigate("/consenso-pre");
            } else {
              navigate("/junta-medica");
            }
          }
        },
      });
    }
  };

  const voltarEtapa = () => {
    let currentStage = stage - 1;
    setStage(currentStage >= 1 ? currentStage : 1);
  };

  const [stage, setStage] = useState(1);

  const setModel = (name, value, saveCache = false) => {
    let keys = name?.split(".") || false;
    if (keys && keys.length > 1) {
      name = keys[0];
      value = { ...form[name], ...{ [keys[1]]: value } };
    }
    if (name === "form") {
      setForm((prevState) => ({ ...prevState, ...value }));
    } else {
      setForm((prevState) => ({ ...prevState, ...{ [name]: value } }));
    }
    if (saveCache) {
      localStorage.setItem("process", JSON.stringify(form));
    }
    validateStages();
  };

  const isDisplayed = () => {
    switch (stage) {
      case 1:
        return (
          <DadosGerais
            form={form}
            startDate={startDate}
            expireDate={expireDate}
            ansDate={ansDate}
            permitirEdicao={true}
            modoEdicao={false}
            validacao={validacao}
            setModel={setModel}
            options={options}
          />
        );
      case 2:
        return (
          <DadosProcesso
            form={form}
            validacao={validacao}
            setModel={setModel}
            options={options}
            permitirEdicao={true}
            modoEdicao={false}
          />
        );
      case 3:
        return (
          <Anexos
            form={form}
            validacao={validacao}
            setModel={setModel}
            options={options}
            permitirEdicao={true}
          />
        );
      default:
        return false;
    }
  };

  config.onChange = () => {
    setRender(tools.uuidv4());
  };

  useEffect(() => {
    tools
      .addWorkingDays(config.dataHoraAtual(), 1)
      .then((date) => setStartDate(date));
  }, [form?.id, form.id_processo_tipo, form?.inicio_processo, render]);

  useEffect(() => {
    let currentTime =
      form?.id && form.inicio_processo
        ? form.inicio_processo
        : startDate + " " + tools.getTime() + ":00";

    let tipo_processo = form?.id_processo_tipo
      ? parseInt(form.id_processo_tipo)
      : false;

    switch (tipo_processo) {
      case 1:
        tools
          .addWorkingDays(currentTime, 3)
          .then((date) => setExpireDate(date)); //D0 + 2
        break;
      case 2:
        tools
          .addWorkingDays(currentTime, 2)
          .then((date) => setExpireDate(date)); //D0 + 24hrs
        break;
      default:
        tools
          .addWorkingDays(currentTime, 8)
          .then((date) => setExpireDate(date)); //D0 + 7
    }
  }, [
    form?.id,
    form.id_processo_tipo,
    form?.inicio_processo,
    startDate,
    render,
  ]);

  useEffect(() => {
    //let tipo_processo = (form?.id_processo_tipo) ? parseInt(form.id_processo_tipo) : false;
    let tipo_atendimento = form?.id_processo_tipo_atendimento
      ? parseInt(form.id_processo_tipo_atendimento)
      : false;

    if (tipo_atendimento === 1 && form.data_atendimento) {
      tools
        .addWorkingDays(form.data_atendimento, 21)
        .then((date) => setAnsDate(date));
    } else if (tipo_atendimento === 2 && form.data_atendimento) {
      tools
        .addWorkingDays(form.data_atendimento, 10)
        .then((date) => setAnsDate(date));
    } else {
      setAnsDate("");
    }
  }, [
    form.id_processo_tipo,
    form.id_processo_tipo_atendimento,
    form.data_atendimento,
    render,
  ]);

  const validateStages = () => {
    setStages(
      stages.map((item, i) => {
        item.completed = item.validate(form);
        return item;
      })
    );
  };

  useEffect(() => {
    validateStages();
    // eslint-disable-next-line
  }, [form?.id]);

  return (
    <Layout className="page cadastro">
      <ModalAlert
        title={modalData?.title}
        text={modalData?.text}
        show={modalData?.show}
        onAccept={modalData?.onAccept}
        onClose={modalData?.onClose}
        buttonCancel={modalData?.buttonCancel}
        setModel={setModalData}
      />
      {!options?.tiposAtendimento ? <Preloader /> : ""}
      <p className="page-title">
        {form?.id_protocolo ? form?.desc_processo_etapa : "Novo Processo"}
      </p>
      <h1 className="flex ai-fc f600-22 mb-30 gap-10">
        <i className="icon icon-arrow-left i-25" />{" "}
        {form?.id_protocolo ? form?.id_protocolo : "Novo Processo"}
      </h1>
      <div className="form-header gap-20">
        <div className="container-stages gap-25">
          {stages.map((s) => {
            return (
              <Stages
                key={s.id}
                item={s}
                stage={stage}
                options={options}
                form={form}
                onClick={() => {
                  validateStages();
                  if (form?.id) setStage(s.id);
                }}
              />
            );
          })}
        </div>
      </div>
      {isDisplayed()}
      <div className="form-rodape mt-70">
        {
          <button
            className="btn btn-outline-primary f700-16"
            onClick={cancelar}
          >
            Cancelar
          </button>
        }
        {form?.id && stage > 1 && (
          <button
            className="btn btn-outline-primary f700-16"
            onClick={voltarEtapa}
          >
            Voltar
          </button>
        )}
        <button
          className="btn btn-primary f700-16"
          onClick={salvar}
          disabled={updateStatus}
        >
          {updateStatus ? <Loading /> : stage === 3 ? "Concluir" : "Salvar"}
        </button>
      </div>
    </Layout>
  );
}
