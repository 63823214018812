import React, { useEffect, useRef, useState } from 'react';
import ReactPaginate from "react-paginate";
import InputForm from "../../components/Form/InputForm.js";
import Layout from "../../components/Layout.js";
import Search from "../../components/Form/Search.js";
import MenuDots from "../../components/MenuDots.js";
import '../../assets/styles/pages/Processo/Processo.scss'
import 'pure-css-loader/src/loader-default.sass';
import business from "../../services/business.js";
import layout from '../../lib/layout.js';
import tools from '../../lib/tools.js';
import config from '../../config/config.js';
import ModalAlert from '../../components/Modais/ModalAlert.js';
import { useAlert } from 'react-alert';
import ModalDuplicar from '../../components/Modais/ModalDuplicar.js';
import moment from 'moment';

export default function Processo({ name, id }) {
  layout.openMenu(`/${name}`);
  document.title = 'säästo';

  const user = config.get();
  const alert = useAlert();
  const [pager, setPager] = useState({
    page: 1,
    total_pages: 0,
    total_rows: 0,
  });
  const [meusProcessosBtn, setMeusProcessosBtn] = useState(false)
  const [filtro, setFiltro] = useState(false)
  const [filtros, setFiltros] = useState({
    id_operadora: (!config.isSaastoUser()) ? user?.id_operadora : '',
    desc_operadora: (!config.isSaastoUser()) ? user?.desc_operadora : '',
    data_criacao_inicio: '',
    data_criacao_fim: '',
    data_conclusao_inicio: '',
    data_conclusao_fim: '',
    id_usuario: '',
    meus_processos: ''
  });

  const [processos, setProcessos] = useState([])
  const [options, setOptions] = useState({})
  const [modalData, setModalData] = useState({});

  const [modalDataDuplicar, setModalDataDuplicar] = useState({
    id: null,
    id_protocolo: null,
    id_processo_tipo: null,
    show: false
  });

  const searchOperadora = async (e) => {
    e.preventDefault();
    return await business.getOperadora(e.target.value);
  }

  const searchResponsavel = async (e) => {
    e.preventDefault();
    return await business.getResponsaveisByOperadora(filtros?.id_operadora, e.target.value);
  }

  const getCombosOPS = async () => {

    let etapas = [
      { id: 0, nome: 'Todas' },
      { id: 12, nome: 'Rascunho' },
      { id: 1, nome: 'Espera' },
      { id: 2, nome: 'Análise' },
      { id: 6, nome: 'Análise técnica' },
      { id: 8, nome: 'Ciência de abertura' },
      { id: 7, nome: 'Consenso Pós' },
      { id: 9, nome: 'Definir desempatador' },
      { id: 3, nome: 'Análise desempatador' },
      { id: 10, nome: 'Validação' },
      { id: 11, nome: 'Ciência de conclusão' },
      { id: 4, nome: 'Finalizado' },
      { id: 5, nome: 'Cancelado' }
    ];
    let status = [
      { id: 0, nome: 'Todos' },
      { id: 24, nome: 'Não enviado' },
      { id: 4, nome: 'Documentação pendente' },
      { id: 3, nome: 'Informações pendentes' },
      { id: 7, nome: 'Finalizado' },
      { id: 12, nome: 'Finalizado por Consenso' },
      { id: 8, nome: 'Cancelado' },
    ];
    setOptions({ ...{ etapas, status }, ...options })
  }

  const getCombos = async () => {

    let etapas = [
      { id: 0, nome: 'Todas' },
      { id: 12, nome: 'Rascunho' },
      { id: 1, nome: 'Espera' },
      { id: 2, nome: 'Análise' },
      { id: 6, nome: 'Análise técnica' },
      { id: 8, nome: 'Ciência de abertura' },
      { id: 7, nome: 'Consenso Pós' },
      { id: 13, nome: 'Consenso Pós (Enfermeira)' },
      { id: 13, nome: 'Consenso Pré' },
      { id: 9, nome: 'Definir desempatador' },
      { id: 3, nome: 'Análise desempatador' },
      { id: 10, nome: 'Validação' },
      { id: 11, nome: 'Ciência de conclusão' },
      { id: 4, nome: 'Finalizado' },
      { id: 5, nome: 'Cancelado' }
    ];
    let status = [
      { id: 0, nome: 'Todos' },
      { id: 1, nome: 'Aguardando' },
      { id: 14, nome: 'Aguardando ciência dos envolvidos' },
      { id: 23, nome: 'Aguardando enfermeira' },
      { id: 22, nome: 'Aguardando desempatador' },
      { id: 15, nome: 'Aguardando PA' },
      { id: 2, nome: 'Analisando informações' },
      { id: 8, nome: 'Cancelado' },
      { id: 11, nome: 'Compondo Junta' },
      { id: 5, nome: 'Concluído' },
      { id: 10, nome: 'Concluído por consenso' },
      { id: 21, nome: 'Devolvido para ajustes' },
      { id: 4, nome: 'Documentação pendente' },
      { id: 6, nome: 'Em análise pelo desempatador' },
      { id: 9, nome: 'Em consenso' },
      { id: 18, nome: 'Em consenso pós' },
      { id: 17, nome: 'Encaminhado para consenso' },
      { id: 16, nome: 'Escolher desempatador' },
      { id: 7, nome: 'Finalizado' },
      { id: 12, nome: 'Finalizado por Consenso' },
      { id: 3, nome: 'Informações pendentes' },
      { id: 20, nome: 'Parecer em análise' },
      { id: 13, nome: 'Recusa de consenso' },
      { id: 19, nome: 'Solicitar exames complementares' },
      { id: 24, nome: 'Não enviado' },
    ];
    setOptions({ ...{ etapas, status }, ...options })
  }

  const setModel = (name, value) => {
    setFiltros({ ...filtros, ...{ [name]: value } });
  }

  const setSearch = (name, data) => {
    setFiltros({
      ...filtros, ...{
        [`id_${name}`]: data?.id || '',
        [`desc_${name}`]: data?.nome || '',
      }
    });
  }

  const handlePageClick = (e) => {
    getSearch(e.selected + 1);
  };

  const getDias = async (inicio_processo) => {
    let total = await tools.diasUteisEntreDatas(inicio_processo, config.dataHoraAtual());
    return total;
  }

  const getSearch = async (page = null, value = '', loading = true, user_id = false) => {
    setLoading(loading);
    let processos = await business.getAllProcessos((page === null) ? pager.page : page, 10, value, {
      id_processo_tipo: id,
      id_operadora: (user.tipo === '2' || user.tipo === '3') ? user.id_operadora : ((filtros?.id_operadora) ? filtros.id_operadora : ''),
      id_desempatador: user.tipo === '6' ? user.id : '',
      id_processo_etapa: filtros?.id_processo_etapa || '',
      id_processo_etapa_status: filtros?.id_processo_etapa_status || '',
      id_usuario: filtros?.id_usuario || '',
      data_criacao_inicio: filtros?.data_criacao_inicio || '',
      data_criacao_fim: filtros?.data_criacao_fim || '',
      data_conclusao_inicio: filtros?.data_conclusao_inicio || '',
      data_conclusao_fim: filtros?.data_conclusao_fim || '',
      meus_processos: user_id || ''
    });

    let data = processos?.response || [];

    data = data.map(async (row) => {
      row.dias = await getDias(row.inicio_processo).then(value => { return value; });
      row.diasPA = await getDias(row.marcador_sla).then(value => { return value; });
      return row;
    });

    Promise.all(data).then((results) => {
      setProcessos(results);
    });

    if (processos?.response && pager.page && processos?.pager?.paginas < pager.page) {
      pager.page = 1;
      return getSearch();
    }
    pager.page = processos?.pager?.pagina;
    setPager({
      page: processos?.pager?.pagina,
      total_pages: processos?.pager?.paginas,
      total_rows: processos?.pager?.total
    });
    setLoading(false);
    setSearchLoading(false);

    if (meusProcessosBtn === true) {
      setMeusProcessosBtn(false)
    }
  }

  const handleWindowSizeChange = (mobileSize) => {
    let checkIsMobile = window.innerWidth < mobileSize;
    setIsMobile(checkIsMobile);
  }

  const cancelar = async (e, form) => {
    e.preventDefault();
    if (form?.id) {
      return setModalData({
        text: 'Deseja realmente cancelar o processo ' + form?.id_protocolo + '?',
        show: true,
        buttonCancel: true,
        onAccept: async (e) => {
          e.preventDefault();
          form.id_processo_etapa = 5;
          form.id_processo_etapa_status = 8;
          let res = await business.cancelarProcesso(form?.id);
          if (res?.errors?.length) {
            (res.errors).forEach(e => alert.error(e));
          } else {
            await getSearch(null, '', false);
            alert.success('O processo foi cancelado com sucesso!');
          }
        }
      })
    }
  }

  const cancelarPosParecer = async (e, form) => {
    e.preventDefault();
    if (form?.id) {
      return setModalData({
        text: 'Deseja realmente cancelar o processo pós parecer ' + form?.id_protocolo + '?',
        show: true,
        buttonCancel: true,
        onAccept: async (e) => {
          e.preventDefault();
          form.id_processo_etapa = 5;
          form.id_processo_etapa_status = 46;
          let res = await business.cancelarPosParecerProcesso(form?.id);
          if (res?.errors?.length) {
            (res.errors).forEach(e => alert.error(e));
          } else {
            await getSearch(null, '', false);
            alert.success('O processo pós parecer foi cancelado com sucesso!');
          }
        }
      })
    }
  }

  const limparFiltros = () => {
    //limpar campos dos filtros e fazer nova busca
    /*     const elementos = [
          "id_processo_etapa",
          "id_processo_etapa_status",
          "data_criacao_inicio",
          "data_criacao_fim",
          "data_conclusao_inicio",
          "data_conclusao_fim"
        ];
        elementos.forEach(id => {
          const elemento = document.getElementById(id);
          if (elemento) {
            elemento.value = '';
          }
        });
        if ([1, 4, 5, 6, 7, 8].includes(parseInt(user.tipo))) {
          const id_operadora = document.getElementById("id_operadora");
          const id_usuario = document.getElementById("id_usuario");
          if (id_operadora) id_operadora.value = '';
          if (id_usuario) id_usuario.value = '';
        }
        if ([3].includes(parseInt(user.tipo))) {
          const id_usuario = document.getElementById("id_usuario");
          if (id_usuario) id_usuario.value = '';
        } */
    setFiltros({});
    setFiltro(false);
    window.location.reload();
    //getSearch(null, '', true);
  }

  const getMeusProcessos = async () => {
    const toggleButton = !meusProcessosBtn;
    setMeusProcessosBtn(toggleButton);

    const userId = (toggleButton) ? user.id : null;
    getSearch(1, '', true, userId);
  };

  useEffect(() => {
    window.addEventListener('resize', () => {
      return handleWindowSizeChange(1180);
    });
  }, []);

  const [isMobile, setIsMobile] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const responsavelRef = useRef();

  useEffect(() => {
    (async () => {
      await getSearch();
      if (config.user.tipo === '2' || config.user.tipo === '3') {
        getCombosOPS()
      }
      else {
        getCombos()
      };
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, config])

  //  Autoreload de mouse estiver parado ou janela inativa apenas
  const [mouseIdle, setMouseIdle] = useState(false);

  useEffect(() => {
    let idleTimer;

    const handleMouseMove = () => {
      clearTimeout(idleTimer);
      setMouseIdle(false);
      idleTimer = setTimeout(() => {
        setMouseIdle(true);
      }, 50000); // Tempo para considerar mouse inativo
    };

    const handleWindowFocus = () => {
      setMouseIdle(false);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('focus', handleWindowFocus);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('focus', handleWindowFocus);
      clearTimeout(idleTimer);
    };
  }, []);

  useEffect(() => {
    if (mouseIdle) {
      const interval = setTimeout(() => {
        window.location.reload();
      }, 5000);

      return () => clearTimeout(interval); // Limpa o timeout quando o componente é desmontado
    }
  }, [mouseIdle]);

  return (
    <Layout className="view-listagem">
      <ModalAlert title={modalData?.title} text={modalData?.text} show={modalData?.show} onAccept={modalData?.onAccept} onClose={modalData?.onClose} buttonCancel={modalData?.buttonCancel} setModel={setModalData} />
      <ModalDuplicar data={modalDataDuplicar} onChange={async (e, slug, id_processo) => {
        e.stopPropagation();
        if (slug && id_processo) {
          tools.openURL(`/${slug}/${id_processo}`);
        } else {
          await getSearch(null, '', false);
        }
      }} hideModal={async (e) => {
        e.preventDefault();
        setModalDataDuplicar({
          id: null,
          id_protocolo: null,
          id_processo_tipo: null,
          show: false
        });
      }} />
      <p className="page-title">Listagem de processos</p>
      <h2 className="title flex jc-sb ai-fe black-text mb-40 gap-10">
        {layout.title(name)}
      </h2>
      <div className={`flex jc-sb ai-fc mb-${filtro ? `2` : `6`}0`}>
        <div className="container-search-filter flex ai-fc jc-sb gap-25">
          <Search value={filtro?.texto || ''} name="texto" onChange={(value) => {
            setSearchLoading(true);
            getSearch(1, value, false);
            setModel('texto', value);
          }}
            searchOnTyping={isMobile}
            loading={searchLoading} />
          {user.tipo !== '6' && <button className={`btn btn-${!meusProcessosBtn ? `outline-` : ''}primary f700-16 jc-sb w-350px`}
            onClick={getMeusProcessos}>
            <span className="desktop">Meus processos</span> <i className="icon icon-eye i-20 blue" />
          </button>}
          <button className={`btn btn-${!filtro ? `outline-` : ''}primary filtros f700-16 jc-sb`} onClick={() => setFiltro(!filtro)}>
            <span className="desktop">Filtrar </span> <i className={`icon ${!filtro ? `icon-settings-sliders ` : `icon-exit `}i-20 ml-5 blue`} />
          </button>
        </div>
      </div>
      {filtro && <div className="filtro-consulta form-container grid col-12">
        <div className="w-100">
          <div className="grid col-12 md-gap">
            <InputForm label="Operadora" type="search" onResetSearch={() => {
              filtros.id_operadora = '';
              filtros.desc_operadora = '';
              responsavelRef.current.clearSearch();
            }} {...((user.tipo === '2' || user.tipo === '3') ? { disabled: true } : {})} className="span-3" onSearch={searchOperadora} name="id_operadora" model={(...params) => setSearch('operadora', ...params)}
              exibicao={false} value={filtros.desc_operadora} />
            <InputForm label="Responsável" type="search" ref={responsavelRef} {...((user.tipo === '6' || !filtros.id_operadora) ? { disabled: true } : {})} className="span-3" onSearch={searchResponsavel} name="id_usuario" model={(...params) => setSearch('usuario', ...params)}
              exibicao={false} value={filtros.id_usuario} />
            <InputForm label="Etapa" className="span-3" name="id_processo_etapa" type="select"
              value={filtros.id_processo_etapa} options={options?.etapas} model={setModel} />
            <InputForm label="Status" className="span-3" name="id_processo_etapa_status" type="select"
              value={filtros.id_processo_etapa_status} options={options?.status} model={setModel} />
          </div>
        </div>
        <div className="w-100">
          <div className="grid col-12">
            <InputForm label="Data de cadastro (Inicial)" type="date" id="data_criacao_inicio" name="data_criacao_inicio" model={setModel} value={filtros.data_criacao_inicio} />
            <span className="text-center interval">-</span>
            <InputForm label="Data de cadastro (Final)" type="date" className="input-padding" id="data_criacao_fim" name="data_criacao_fim" model={setModel} value={filtros.data_criacao_fim} />
            <InputForm label="Data de conclusão (Inicial)" type="date" id="data_conclusao_inicio" name="data_conclusao_inicio" model={setModel} value={filtros.data_conclusao_inicio} />
            <span className="text-center interval">-</span>
            <InputForm label="Data de conclusão (Final)" type="date" className="input-padding" id="data_conclusao_fim" name="data_conclusao_fim" model={setModel} value={filtros.data_conclusao_fim} />
            <button className="btn btn-primary f700-16 button-filtrar" onClick={() => {
              getSearch(1, filtros?.texto || '', true);
            }}>
              Aplicar filtros
            </button>
            <span className="pointer float-right ml-20 mt-30" onClick={limparFiltros}>
              <i className="icon icon-trash watermelon i-25" title="Limpar filtros" />
            </span>
          </div>
        </div>
      </div>}

      {
        loading ? <div className="loader loader-default is-active" /> : (<div>
          <div className="table-mobile">
            {(processos || []).map((p, i) => {
              return <div className="item pointer" key={`mobile-${i}`}>
                <div>

                  <div className="fn-16 linha flex jc-sb" onClick={() => tools.openURL(`/${p.slug_processo_tipo}/${p.id}`)}>
                    ID
                    <span>
                      <strong>{p.id_protocolo}</strong>
                    </span>
                  </div>
                  <div className="fn-16 linha flex jc-sb">
                    Responsável (Operadora)
                    <span>
                      {p.desc_operadora}
                    </span>
                  </div>
                  <div className="fn-16 linha flex jc-sb">
                    Beneficiário
                    <span>
                      {p.nome_beneficiario}
                    </span>
                  </div>
                  <div className="fn-16 linha flex jc-sb">
                    Nº guia
                    <span className="flex jc-fc gap-5 ai-fc">
                      {p?.guia}
                    </span>
                  </div>
                  <div className="fn-16 linha flex jc-sb">
                    Etapa/Status
                    <span className="flex jc-fc gap-5 ai-fc">
                      {p.desc_processo_etapa} - {p.desc_processo_etapa_status}
                    </span>
                  </div>
                  {user.tipo !== '6' && <div className="fn-16 linha flex jc-sb">
                    Prazo säästö
                    <span className="flex jc-fc gap-5 ai-fc">
                      {moment(p?.expiracao_processo).format('DD/MM/YYYY')}
                    </span>
                  </div>}
                  {(user.tipo !== '2' || user.tipo !== '3') && <div className="fn-16 linha flex jc-sb">
                    Prazo ANS
                    <span className="flex jc-fc gap-5 ai-fc">
                      {moment(p?.data_ans).format('DD/MM/YYYY')}
                    </span>
                  </div>}
                  <div className="fn-16 linha flex jc-sb" onClick={() => tools.openURL(`/${p.slug_processo_tipo}/${p.id}`)}>
                    Situação
                    <span className={`badge badge-${(p.id_processo_etapa === "1" || p.id_processo_etapa === "12" || p.id_processo_etapa === "4" || p.id_processo_etapa === "5") ? p.id_processo_etapa : (p.expiracao_processo <= tools.getDate() ? 'atrasado' : ((p.id_processo_etapa_status === "3" || p.id_processo_etapa_status === "4" || p.id_processo_etapa_status === "19" || p.id_processo_etapa_status === "24" || p.id_processo_etapa_status === "26") ? 'pausado' : p.id_processo_etapa))}`}>
                      {p.id_processo_etapa === "12" || p.id_processo_etapa === "1" || p.id_processo_etapa === "4" || p.id_processo_etapa === "5" ? <span className="display-none">{p.id_processo_etapa}</span> :
                        ((p.id_processo_etapa_status === "3" || p.id_processo_etapa_status === "4" || p.id_processo_etapa_status === "19" || p.id_processo_etapa_status === "24" || p.id_processo_etapa_status === "26") ? 'Pausado' :
                          (p.expiracao_processo <= tools.getDate() ? 'Atrasado' : (((tools.formatDateSQL(p.inicio_processo) === tools.getDate()) || (p.dias - p.pausa_dias) < 0) ? 0 : (p.dias - p.pausa_dias - 1)) + " / " + (p?.id_processo_tipo === '1' ? 2 : (p?.id_processo_tipo === '2' ? 1 : 7))))}
                    </span>
                  </div>
                  {user.tipo !== '6' && <div className="fn-16 linha flex jc-sb">
                    Data de conclusão
                    <span className="flex jc-fc gap-5 ai-fc">
                      {p?.data_conclusao ? moment(p?.data_conclusao).format('DD/MM/YYYY') : '-'}
                    </span>
                  </div>}
                  {/* <div className="fn-16 linha flex jc-sb">
                  Valor
                  <span>
                    <NumericFormat value={p.valor} displayType={'text'} thousandSeparator={'.'} prefix="R$ "
                      decimalScale={2} fixedDecimalScale decimalSeparator={','} />
                  </span>
                </div> */}
                </div>
                <div className="w-100 flex jc-fc">
                  <MenuDots row={p} menuPositionTop={true} menuItens={[
                    {
                      label: 'Visualizar',
                      icon: 'icon-eye blue i-25',
                      className: 'menu-icon',
                      onClick: () => (filtro || meusProcessosBtn) ? (tools.openURLTab(`/${p.slug_processo_tipo}/${p.id}`)) : (tools.openURL(`/${p.slug_processo_tipo}/${p.id}`))
                    },
                    {
                      label: 'Duplicar',
                      icon: 'icon-duplicate blue i-25',
                      className: 'menu-icon',
                      noDisplay: [6, 7].includes(parseInt(config.user.tipo)),
                      onClick: (e, item) => {
                        e.preventDefault();
                        setModalDataDuplicar({
                          id: item.id,
                          id_protocolo: item.id_protocolo,
                          id_processo_tipo: item.id_processo_tipo,
                          show: true
                        });
                      }
                    },
                    {
                      label: 'Dossiê',
                      icon: 'icon-dossie blue i-25',
                      className: 'menu-icon',
                      noDisplay: p.id_processo_tipo !== '3',
                      disabled: ![4].includes(parseInt(p.id_processo_etapa)),
                      onClick: (e) => {
                        e.preventDefault();
                        tools.openUrlExternal(`/${p.id}/dossie`)
                      }
                    },
                    {
                      label: 'Cancelar',
                      icon: 'icon-cross-circle blue i-25',
                      className: 'menu-icon',
                      noDisplay: (!config.temPermissao('listagem:processo_cancelar:editar') || ([4, 5].includes(parseInt(p.id_processo_etapa)))),
                      disabled: [3, 10, 11].includes(parseInt(p.id_processo_etapa)),
                      onClick: async (e) => {
                        e.stopPropagation()
                        cancelar(e, p);
                      }
                    },
                    {
                      label: 'Cancelar pós parecer',
                      icon: 'icon-cross-circle blue i-25',
                      className: 'menu-icon',
                      noDisplay: (parseInt(user.tipo) !== 1),
                      disabled: ![3, 10, 11].includes(parseInt(p.id_processo_etapa)),
                      onClick: async (e) => {
                        e.stopPropagation()
                        cancelarPosParecer(e, p);
                      }
                    }
                  ]} />
                </div>
              </div>
            })}
            {(!loading && (!processos || !processos.length)) ? <div>Nenhum processo foi encontrado.</div> : ''}
          </div>
          <table className="w-100 table-desktop">
            <thead className="f300-14">
              <tr>
                <th className="text-center bold">ID</th>
                {(user.tipo !== '2' && user.tipo !== '3') && <th className="text-left bold">Operadora <br />(Responsável)</th>}
                <th className="text-left bold">Beneficiário</th>
                <th className="text-center bold">Nº guia</th>
                <th className="text-center bold">Responsáveis säästo <br />(Assistente / Enfermeira)</th>
                <th className="text-center bold">Etapa/Status</th>
                {user.tipo !== '6' && <th className="text-center bold">Prazo säästö</th>}
                <th className="text-center bold">Prazo ANS</th>
                <th className="text-center bold">Situação</th>
                <th></th>
                {(![2, 3, 6].includes(parseInt(user.tipo))) && <th className="text-center bold">Data de conclusão</th>}

                <th></th>
              </tr>
            </thead>
            <tbody>
              {(processos || []).map((p, i) => {
                return <tr key={`desktop-${i}`} className={`hover-highlight fn-14${((p.id_processo_etapa_status === "3" || p.id_processo_etapa_status === "4" || p.id_processo_etapa_status === "19" || p.id_processo_etapa_status === "24" || p.id_processo_etapa_status === "26") && (user.tipo === '2' || user.tipo === '3')) ? ' bold-red' : ''}`} onClick={() => (filtro || meusProcessosBtn) ? (tools.openURLTab(`/${p.slug_processo_tipo}/${p.id}`)) : (tools.openURL(`/${p.slug_processo_tipo}/${p.id}`))} >
                  <td className="text-center" style={{ minWidth: '100px' }}>
                    <span className={`badge badge-${(p.id_processo_etapa === "1" || p.id_processo_etapa === "12" || p.id_processo_etapa === "4" || p.id_processo_etapa === "5") ? p.id_processo_etapa : (p.expiracao_processo <= tools.getDate() ? 'atrasado' : ((p.id_processo_etapa_status === "3" || p.id_processo_etapa_status === "4" || p.id_processo_etapa_status === "19" || p.id_processo_etapa_status === "24" || p.id_processo_etapa_status === "26") ? 'pausado' : p.id_processo_etapa))} badge-no-after`}>
                      {p.id_protocolo}
                    </span>
                  </td>
                  {(user.tipo !== '2' && user.tipo !== '3') && <td className="text-left">{p.desc_operadora}<br />({p.nome_usuario})</td>}
                  <td className="text-left">{p.nome_beneficiario}</td>
                  <td className="text-center" style={{ minWidth: '95px' }}>
                    {p.guia}
                  </td>
                  <td className="text-center" style={{ minWidth: '120px' }}>
                    {p.nome_assistente ? p.nome_assistente : '-'}<br />{p.nome_enfermeira ? p.nome_enfermeira : '-'}
                  </td>
                  <td className="text-center" style={{ minWidth: '180px' }}>
                    <div className="flex ai-fc jc-fc fn-14 gap-5">
                      {p.desc_processo_etapa}:<br /> {p.desc_processo_etapa_status}
                    </div>
                  </td>
                  {user.tipo !== '6' && <td className="text-center" style={{ minWidth: '95px' }}>
                    {moment(p?.expiracao_processo).format('DD/MM/YYYY')}
                  </td>}
                  <td className="text-center" style={{ minWidth: '95px' }}>
                    {p.data_ans ? moment(p?.data_ans).format('DD/MM/YYYY') : '-'}
                  </td>
                  <td className="text-center">
                    <span className={`badge badge-${(p.id_processo_etapa === "1" || p.id_processo_etapa === "12" || p.id_processo_etapa === "4" || p.id_processo_etapa === "5") ? p.id_processo_etapa : (p.expiracao_processo <= tools.getDate() ? 'atrasado' : ((p.id_processo_etapa_status === "3" || p.id_processo_etapa_status === "4" || p.id_processo_etapa_status === "19" || p.id_processo_etapa_status === "24" || p.id_processo_etapa_status === "26") ? 'pausado' : p.id_processo_etapa))}`}>
                      {p.id_processo_etapa === "12" || p.id_processo_etapa === "1" || p.id_processo_etapa === "4" || p.id_processo_etapa === "5" ? <span className="display-none">{p.id_processo_etapa}</span> :
                        ((p.id_processo_etapa_status === "3" || p.id_processo_etapa_status === "4" || p.id_processo_etapa_status === "19" || p.id_processo_etapa_status === "24" || p.id_processo_etapa_status === "26") ? 'Pausado' :
                          (p.expiracao_processo <= tools.getDate() ? 'Atrasado' : (((tools.formatDateSQL(p.inicio_processo) === tools.getDate()) || (p.dias - p.pausa_dias) < 0) ? 0 : (p.dias - p.pausa_dias - 1)) + " / " + (p?.id_processo_tipo === '1' ? 2 : (p?.id_processo_tipo === '2' ? 1 : 7))))}
                    </span>
                    {/* {(p.id_processo_tipo === '3' && ![1, 4, 5, 12].includes(parseInt(p.id_processo_etapa))) &&
                      <span className="flex mt-5 lt-gap jc-fc">
                        <i className="circle circle-done cursor-help" title="Comunicação de abertura ao beneficiário"/>
                        <i className="circle circle-progress cursor-help" title="Comunicação de abertura ao profissional assistente" />
                        <i className="circle circle-none cursor-help" title="Comunicação de fechamento ao beneficiário" />
                        <i className="circle circle-none cursor-help" title="Comunicação de fechamento ao profissional assistente" />
                      </span>} */}
                  </td>
                  <td>
                    {([1, 4, 5, 7, 8].includes(parseInt(config.user.tipo)) && (p.id_processo_etapa === "9" || p.id_processo_etapa_status === "15") && p.marcador_sla && p.diasPA > 2) && <i className="icon icon-important i-20" title="Expirado prazo de 48hrs do Profissional Assistente." />}
                  </td>
                  {(![2, 3, 6].includes(parseInt(user.tipo))) && <td className="text-center" style={{ minWidth: '95px' }}>
                    {p.data_conclusao ? moment(p?.data_conclusao).format('DD/MM/YYYY') : '-'}
                  </td>}
                  <td className="text-center" style={{ width: '35px' }}>
                    <MenuDots row={p} menuPositionTop={processos.length - i <= 2}
                      menuItens={[
                        {
                          label: 'Visualizar',
                          icon: 'icon-eye blue i-25',
                          className: 'menu-icon',
                          onClick: () => (filtro || meusProcessosBtn) ? (tools.openURLTab(`/${p.slug_processo_tipo}/${p.id}`)) : (tools.openURL(`/${p.slug_processo_tipo}/${p.id}`))
                        },
                        {
                          label: 'Duplicar',
                          icon: 'icon-duplicate blue i-25',
                          className: 'menu-icon',
                          noDisplay: [6, 7].includes(parseInt(config.user.tipo)),
                          onClick: (e, item) => {
                            e.preventDefault();
                            setModalDataDuplicar({
                              id: item.id,
                              id_protocolo: item.id_protocolo,
                              id_processo_tipo: item.id_processo_tipo,
                              show: true
                            });
                          }
                        },
                        {
                          label: 'Dossiê',
                          icon: 'icon-dossie blue i-25',
                          className: 'menu-icon',
                          noDisplay: p.id_processo_tipo !== '3',
                          disabled: ![4].includes(parseInt(p.id_processo_etapa)),
                          onClick: (e) => {
                            e.preventDefault();
                            tools.openUrlExternal(`/${p.id}/dossie`)
                          }
                        },
                        {
                          label: 'Cancelar',
                          icon: 'icon-cross-circle blue i-25',
                          className: 'menu-icon',
                          noDisplay: (!config.temPermissao('listagem:processo_cancelar:editar') || ([4, 5].includes(parseInt(p.id_processo_etapa)))),
                          disabled: [3, 10, 11].includes(parseInt(p.id_processo_etapa)),
                          onClick: async (e) => {
                            e.stopPropagation()
                            cancelar(e, p);
                          }
                        },
                        {
                          label: 'Cancelar pós parecer',
                          icon: 'icon-cross-circle blue i-25',
                          className: 'menu-icon',
                          noDisplay: (parseInt(user.tipo) !== 1) || ([4, 5].includes(parseInt(p.id_processo_etapa))),
                          disabled: ![3, 10, 11].includes(parseInt(p.id_processo_etapa)),
                          onClick: async (e) => {
                            e.stopPropagation()
                            cancelarPosParecer(e, p);
                          }
                        },
                      ]} />
                  </td>
                </tr>
              })}

              {(!loading && (!processos || !processos.length)) ? <tr>
                <td colSpan={8}>Nenhum processo foi encontrado</td>
              </tr> : ''}
            </tbody>
          </table>
          <div className="component pagination">
            <div className="pagination-container mt-30">
              {pager && pager.total_pages < 2 ? '' : <ReactPaginate
                previousLabel="«"
                nextLabel="»"
                breakLabel="..."
                breakClassName="break-me"
                page={pager.page - 1 ?? 0}
                forcePage={pager.page - 1 ?? 0}
                pageCount={pager.total_pages}
                marginPagesDisplayed={3}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                subContainerClassName="pages pagination"
                activeClassName="active" />}
            </div>
          </div>
        </div>)
      }
    </Layout >
  );
}